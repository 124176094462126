const HeroBannerIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" fill="#EDEFF3" />
      <rect x="2" y="2" width="26" height="19" fill="#4F3FA8" />
      <g clipPath="url(#clip0_266_9349)">
        <path
          d="M10.9703 12.6787L18.0417 12.6787C18.3968 12.6787 18.6846 12.3909 18.6846 12.0359V4.96443C18.6846 4.60939 18.3968 4.32157 18.0417 4.32157L10.9703 4.32157C10.6152 4.32157 10.3274 4.60939 10.3274 4.96443L10.3274 12.0359C10.3274 12.3909 10.6152 12.6787 10.9703 12.6787Z"
          stroke="white"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.9463 8.17871C16.5676 8.17871 17.0713 7.67503 17.0713 7.05371C17.0713 6.43239 16.5676 5.92871 15.9463 5.92871C15.325 5.92871 14.8213 6.43239 14.8213 7.05371C14.8213 7.67503 15.325 8.17871 15.9463 8.17871Z"
          stroke="white"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0427 12.6787C15.8357 11.5762 15.2437 10.5831 14.3724 9.87658C13.5011 9.17009 12.4071 8.79616 11.2856 8.82152C10.962 8.82066 10.6391 8.8508 10.3213 8.91152"
          stroke="white"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6789 10.3708C18.1611 10.1959 17.6182 10.1069 17.0717 10.1072C16.38 10.1057 15.6952 10.2457 15.0596 10.5186"
          stroke="white"
          strokeWidth="0.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="10" y="14" width="9" height="1" fill="#CDCCD7" fillOpacity="0.5" />
      <rect x="13" y="18" width="3" height="1" fill="#CDCCD7" fillOpacity="0.5" />
      <rect x="4" y="16" width="21" height="1" fill="#CDCCD7" fillOpacity="0.5" />
      <defs>
        <clipPath id="clip0_266_9349">
          <rect width="9" height="9" fill="white" transform="translate(10 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HeroBannerIcon;

import { useTranslation } from "react-i18next";
import { Text, Box, Group } from "@mantine/core";

import Section from "../../common/Section";

import { IPage } from "../../../typings/Page";
import { useWatch } from "react-hook-form";
import { IThemeForm } from "../../../typings/Theme";

import NoResults from "../../../assets/default-pages-graphics/no_results.svg";
import EmptyCart from "../../../assets/default-pages-graphics/empty_cart.svg";
import CheckoutSuccess from "../../../assets/default-pages-graphics/checkout_success.svg";
import AuthBackground from "../../../assets/default-pages-graphics/auth_background.svg";
import ImageEditor from "../sidebar/propEditors/ImageEditor";

const defaultPageGraphics = {
  collection: NoResults,
  cart: EmptyCart,
  "checkout-success": CheckoutSuccess,
  "sign-in": AuthBackground,
  "sign-up": AuthBackground,
};

type Props = {
  pageIndex: number;
  page: IPage;
  form: IThemeForm;
};

function PageGraphic({ page, pageIndex, form }: Props) {
  const { t } = useTranslation("pages");
  const graphicUrl = useWatch({ control: form.control, name: `pages.${pageIndex}.graphicUrl` }) as string | undefined;

  return (
    <Section>
      <Group position="apart" noWrap>
        <Box w="40%">
          <Text size={24} weight={400}>
            {t(`graphic.${page.handle}.title`)}
          </Text>
          <Text size={16} weight={300}>
            {t(`graphic.${page.handle}.hint`)}
          </Text>
        </Box>

        <Box w="40%">
          <ImageEditor
            name={`pages.${pageIndex}.graphicUrl`}
            setValue={form.setValue}
            imageUrl={graphicUrl || defaultPageGraphics[page.handle as keyof typeof defaultPageGraphics]}
          />
        </Box>
      </Group>
    </Section>
  );
}

export default PageGraphic;

const AnnouncementBarIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" fill="#EDEFF3" />
      <rect width="30" height="5" fill="#C13749" />
    </svg>
  );
};

export default AnnouncementBarIcon;

import { useMutation } from "@tanstack/react-query";
import { deleteThemeComponent } from "../../api/Theme";
import { ComponentDisplay, ITheme, IThemeComponent } from "../../typings/Theme";
import { useBuilderQuery } from "../custom/useBuilderQuery";
import { useFormContext } from "react-hook-form";

type ThemeComponentsKey = "topComponents" | "bottomComponents" | `pages.${number}.pageComponents`;

type Params = {
  id: number;
  pageIndex: number;
  display: ComponentDisplay;
};

function deleteChild(components: IThemeComponent[], id: number) {
  components.forEach((component) => {
    if (!component.children) return;

    const childIndex = component.children.findIndex((child) => child.id === id);
    if (childIndex !== -1) component.children.splice(childIndex, 1);
    else deleteChild(component.children, id);
  });
}

export function useDeleteThemeComponent(onSuccess?: () => void) {
  const { setActiveComponent } = useBuilderQuery();
  const form = useFormContext<ITheme>();

  return useMutation({
    mutationFn: ({ id }: Params) => deleteThemeComponent(id),
    onSuccess(_, { id, pageIndex, display }) {
      const themeComponentsKey = (
        display === "content" ? `pages.${pageIndex}.pageComponents` : `${display}Components`
      ) as ThemeComponentsKey;
      const themeComponents = form.getValues(themeComponentsKey);

      const index = themeComponents!.findIndex((component) => component.id === id);
      if (index !== -1) {
        themeComponents!.splice(index, 1);
        setActiveComponent(undefined);
      } else {
        deleteChild(themeComponents!, id);
      }

      form.setValue(themeComponentsKey, themeComponents);
      onSuccess?.();
    },
  });
}

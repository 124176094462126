import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateTheme } from "../../api/Theme";
import { ITheme } from "../../typings/Theme";
import { useFormContext } from "react-hook-form";

export const useThemeUpdate = () => {
  const form = useFormContext<ITheme>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ theme }: { theme: ITheme }) => updateTheme(theme),
    onSuccess(updatedTheme) {
      form.reset(updatedTheme);
      queryClient.invalidateQueries(["themes"]);
      queryClient.invalidateQueries(["theme", updatedTheme.id]);
    },
  });
};

import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import { ArrowAutofitDown, AlignLeft, AlignRight } from "tabler-icons-react";
import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";

type Props = { value?: string; setValue: (value: string) => void; colors: string[] };

export const TextEditor = ({ value, setValue, colors }: Props) => {
  const { locale } = useBuilderQuery();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Highlight,
      TextStyle,
      Color,
      TextAlign.configure({
        types: ["heading", "paragraph"],
        alignments: ["start", "center", "end", "justify"],
        defaultAlignment: "start",
      }),
    ],
    content: value,
    onUpdate({ editor }) {
      setValue(editor.getHTML());
    },
  });

  return (
    <RichTextEditor
      editor={editor}
      styles={(theme) => ({
        content: {
          backgroundColor: theme.colors["light-gray"][0],
        },
        control: {
          "&[data-active]": {
            color: theme.colors["secondary-magenta"][0],
            backgroundColor: theme.colors["light-magenta"][0],
          },
        },
      })}
    >
      <RichTextEditor.Toolbar>
        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Bold />
          <RichTextEditor.Italic />
          <RichTextEditor.Underline />
          <RichTextEditor.Strikethrough />
          <RichTextEditor.Highlight />
          <RichTextEditor.ClearFormatting />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Link />
          <RichTextEditor.Unlink />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.ColorPicker colors={colors} />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.H1 />
          <RichTextEditor.H2 />
          <RichTextEditor.H3 />
          <RichTextEditor.H4 />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          <RichTextEditor.Hr />
          <RichTextEditor.Control
            onClick={() => editor?.commands.setHardBreak()}
            aria-label="Add a line break"
            title="Add a line break"
          >
            <ArrowAutofitDown size={16} color="black" strokeWidth={1.5} />
          </RichTextEditor.Control>
          <RichTextEditor.BulletList />
          <RichTextEditor.OrderedList />
        </RichTextEditor.ControlsGroup>

        <RichTextEditor.ControlsGroup>
          {locale === "ar" ? (
            <RichTextEditor.Control
              onClick={() => editor?.commands.setTextAlign("end")}
              aria-label="align left"
              title="align left"
            >
              <AlignLeft size={16} color="gray" />
            </RichTextEditor.Control>
          ) : (
            <RichTextEditor.Control
              onClick={() => editor?.commands.setTextAlign("start")}
              aria-label="align left"
              title="align left"
            >
              <AlignLeft size={16} color="gray" />
            </RichTextEditor.Control>
          )}

          <RichTextEditor.AlignCenter />
          <RichTextEditor.AlignJustify />

          {locale === "ar" ? (
            <RichTextEditor.Control
              onClick={() => editor?.commands.setTextAlign("start")}
              aria-label="align right"
              title="align right"
            >
              <AlignRight size={16} color="gray" />
            </RichTextEditor.Control>
          ) : (
            <RichTextEditor.Control
              onClick={() => editor?.commands.setTextAlign("end")}
              aria-label="align right"
              title="align right"
            >
              <AlignRight size={16} color="gray" />
            </RichTextEditor.Control>
          )}
        </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  );
};

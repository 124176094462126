import { isAxiosError } from "axios";
import { APIError } from "../typings";
import { ComponentDisplay, IComponent, IPropValue, IThemeComponent } from "../typings/Theme";

function filterProps(props: IPropValue[], locale: "en" | "ar") {
  return props.filter((prop) => !prop.locale || prop.locale === locale);
}

export function filterComponent(component: IThemeComponent, locale: "en" | "ar"): IThemeComponent {
  return {
    ...component,
    propValues: filterProps(component.propValues, locale),
    children: component.children?.map((child) => filterComponent(child, locale)),
  };
}

export function validComponents({
  display,
  allComponents,
  themeComponents,
}: {
  display: ComponentDisplay;
  allComponents?: IComponent[];
  themeComponents?: IThemeComponent[];
}) {
  let allValidComponents = allComponents;
  const themeComponentsKeys = themeComponents?.map((component) => component.key);
  if (themeComponentsKeys?.includes("header"))
    allValidComponents = allValidComponents?.filter((component) => component.key !== "header");
  if (themeComponentsKeys?.includes("footer"))
    allValidComponents = allValidComponents?.filter((component) => component.key !== "footer");

  switch (display) {
    case "top":
      allValidComponents = allValidComponents?.filter((component) =>
        ["header", "announcement_bar"].includes(component.key),
      );
      break;
    case "bottom":
      allValidComponents = allValidComponents?.filter((component) =>
        ["footer", "announcement_bar"].includes(component.key),
      );
      break;
    default:
      allValidComponents = allValidComponents?.filter((component) => !["header", "footer"].includes(component.key));
      break;
  }

  return allValidComponents;
}

export function getErrorMessage(error: APIError | unknown | null) {
  if (isAxiosError(error)) return error.response?.data.error.message as string;
  if (error instanceof Error) return error.message;

  return "";
}

import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { Container, Group, Image, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { TextInput } from "react-hook-form-mantine";

import { useThemes } from "../../../hooks/queries/useThemes";

import ThemeLibrarySkeleton from "../../loadingSkeleton/home/ThemeLibrarySkeleton";
import Button from "../../common/Button";
import ThemesCarousel from "./ThemesCarousel";

import add from "../../../assets/icons/add.svg";

import { important } from "../../../utils/styles";
import { createBlankTheme } from "../../../api/Theme";
import { getErrorMessage } from "../../../utils/builder";
import EmptyThemesList from "./EmptyThemesList";

const ThemeLibrary = () => {
  const { t } = useTranslation("home");
  const { data: themes, isLoading } = useThemes();
  const queryClient = useQueryClient();

  const [createThemeAlertOpened, createThemeAlertHandlers] = useDisclosure(false);
  const form = useForm({ defaultValues: { name: "" } });

  async function createTheme(data: { name: string }) {
    try {
      form.clearErrors();
      await createBlankTheme(data.name);
      queryClient.refetchQueries(["themes"]);
      createThemeAlertHandlers.close();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      form.setError("name", { type: "manual", message: errorMessage });
    }
  }

  return (
    <Container fluid bg="white" px={30} pr={0} py={50}>
      <Group position="apart">
        <div>
          <Text size={30} weight={400} color="primary-black" mb={5}>
            {t("theme_library.title")}
          </Text>
          <Text size={16} weight={300} color="primary-black" mb={40}>
            {t("theme_library.description")}
          </Text>
        </div>

        {themes && themes.length !== 0 && (
          <Button
            mode="primary"
            variant="light"
            color="light-magenta"
            h={45}
            leftIcon={<Image src={add} alt="add" />}
            styles={(theme) => ({ root: { border: `1px dashed ${theme.colors["secondary-magenta"][0]}` } })}
            onClick={createThemeAlertHandlers.open}
          >
            <Text size={16} weight={300} color="black">
              {t("theme_library.actions.create")}
            </Text>
          </Button>
        )}
      </Group>
      {isLoading && <ThemeLibrarySkeleton />}
      {themes && themes.length === 0 && <EmptyThemesList />}
      {themes && themes.length !== 0 && <ThemesCarousel themes={themes} />}

      <Modal
        opened={createThemeAlertOpened}
        onClose={createThemeAlertHandlers.close}
        closeButtonProps={{ "aria-label": "close create theme dialog" }}
        centered
        size="lg"
        title={
          <Text fw="bold" color="dark-gray">
            {t("theme_library.name_theme_dialog.title")}
          </Text>
        }
      >
        <Text>{t("theme_library.name_theme_dialog.description")}</Text>
        <form onSubmit={form.handleSubmit(createTheme)}>
          <Group mt={20} spacing={5}>
            <TextInput
              name="name"
              control={form.control}
              placeholder={t("theme_library.name_theme_dialog.name_placeholder")}
              styles={{ root: { flexGrow: important(1) } }}
            />
            <Button mode="primary" type="submit" loading={form.formState.isSubmitting}>
              {t("theme_library.name_theme_dialog.save")}
            </Button>
          </Group>
        </form>
      </Modal>
    </Container>
  );
};

export default ThemeLibrary;

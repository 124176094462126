import { useTranslation } from "react-i18next";
import { Text } from "@mantine/core";

import Section from "../../common/Section";

import { IPage } from "../../../typings/Page";

const PageHeader = ({ page }: { page: IPage }) => {
  const { t, i18n } = useTranslation("pages");

  return (
    <Section>
      <Text size={24} weight={400}>
        {page.id
          ? t("header.edit-title", { name: i18n.language === "ar" ? page.nameAr : page.nameEn })
          : t("header.add-title")}
      </Text>
      <Text size={16} weight={300}>
        {t("header.desc")}
      </Text>
    </Section>
  );
};

export default PageHeader;

import API from "./API";
import { apiPrefix } from "./constants";

import { ComponentDisplay, ITheme, IThemeComponent } from "../typings/Theme";

type ThemesResponse = {
  success: boolean;
  themes: ITheme[];
};

type ThemeResponse = {
  success: boolean;
  theme: ITheme;
};

type ThemeComponentResponse = {
  success: boolean;
  data: IThemeComponent;
};

const includes = JSON.stringify([
  { relation: "top_components" },
  { relation: "bottom_components" },
  { relation: "pages" },
  { relation: "page_components" },
]);

export const getThemes = async () => {
  const response = await API.get<ThemesResponse>(`${apiPrefix}/themes`);
  return response.data.themes;
};

export async function createDefaultTheme() {
  const response = await API.post<{ success: boolean }>(`${apiPrefix}/themes/create_default_theme`);
  return response.data.success;
}

export const getTheme = async (id: number) => {
  const response = await API.get<ThemeResponse>(`${apiPrefix}/themes/${id}`, {
    headers: { locale: "all" },
    params: { includes },
  });
  return response.data.theme;
};

export async function createBlankTheme(name: string) {
  const response = await API.post<ThemeResponse>(`${apiPrefix}/themes`, { name }, { params: { includes } });
  return response.data.theme;
}

export const updateTheme = async (theme: ITheme) => {
  const id = theme.id;
  const response = await API.put<ThemeResponse>(`${apiPrefix}/themes/${id}/save_changes`, theme, {
    headers: { locale: "all" },
    params: { includes },
  });
  return response.data.theme;
};

export const publishTheme = async (theme: Partial<ITheme>) => {
  const id = theme.id;
  const response = await API.put<ThemeResponse>(`${apiPrefix}/themes/${id}/publish_changes`, theme, {
    headers: { locale: "all" },
    params: { includes },
  });
  return response.data.theme;
};

export const createTheme = async (storeTemplateId: number) => {
  const response = await API.post<ThemeResponse>(`${apiPrefix}/themes/create_from_store_template`, { storeTemplateId });
  return response.data;
};

export async function createThemeComponent(props: {
  themeId: number;
  pageId?: number;
  parentId?: number;
  componentId: number;
  display: ComponentDisplay;
}) {
  const response = await API.post<ThemeComponentResponse>(`${apiPrefix}/theme_components`, props);
  return response.data.data;
}

export async function addThemeSection(props: { themeId: number; pageId?: number; sectionTemplateId: number }) {
  const response = await API.post<ThemeComponentResponse>(
    `${apiPrefix}/theme_components/create_from_section_template`,
    props,
  );
  return response.data.data;
}

export async function deleteThemeComponent(id: number) {
  const response = await API.delete<{ success: boolean }>(`${apiPrefix}/theme_components/${id}`);
  return response.data.success;
}

export async function deleteTheme(id: number) {
  const response = await API.delete<{ success: boolean }>(`${apiPrefix}/themes/${id}`);
  return response.data.success;
}

import { useTranslation } from "react-i18next";
import { Button, FileButton, Text, useMantineTheme, Image, Flex, Anchor, Box } from "@mantine/core";

import GarbageCan from "../../../../icons/GarbageCan";
import ReplaceIcon from "../../../../icons/ReplaceIcon";
import ImagePlaceholder from "../../../../../assets/image-placeholder.svg";

type Props = {
  label?: string;
  imageUrl?: string;
  onChange: (image: File | null) => void;
};

const ImageUploader = ({ label, imageUrl, onChange }: Props) => {
  const { t } = useTranslation("sections");
  const theme = useMantineTheme();

  if (imageUrl) {
    return (
      <Box>
        <Image width="100%" height={139} src={imageUrl} alt={label || "image"} fit="contain" />
        <Flex justify="space-between" mt={15}>
          <FileButton onChange={onChange} accept="image/*">
            {(props) => (
              <Anchor {...props}>
                <Flex align="center" gap={10}>
                  <ReplaceIcon stroke="secondary-magenta" />
                  <Text size={16} weight={300} lh={1.2} underline color="secondary-magenta">
                    {t("config-sidebar.image-uploader.replace-image")}
                  </Text>
                </Flex>
              </Anchor>
            )}
          </FileButton>
          <Anchor component="button" onClick={() => onChange(null)}>
            <Flex align="center" gap={10}>
              <GarbageCan stroke="red" />
              <Text size={16} weight={300} lh={1.2} underline color="red">
                {t("config-sidebar.image-uploader.delete-image")}
              </Text>
            </Flex>
          </Anchor>
        </Flex>
      </Box>
    );
  }

  return (
    <FileButton onChange={onChange} accept="image/*">
      {(props) => (
        <Button
          px={15}
          py={25}
          styles={() => ({
            root: {
              height: 139,
              width: "100%",
              border: "1px dashed #CDCCD7",
              backgroundColor: theme.colors["light-gray"][0],

              "&:hover": {
                border: "1px dashed #CDCCD7",
                backgroundColor: theme.colors["light-gray"][0],
              },
            },
            label: {
              flexDirection: "column",
              justifyContent: "space-between",
            },
          })}
          {...props}
        >
          <Image width={30} height={30} src={ImagePlaceholder} alt="upload image" />
          <Text size={14} weight={400} lh={1.2} color="dark-gray">
            {label}
          </Text>
          <Text size={12} weight={300} lh={1.25} color="med-gray">
            {t("config-sidebar.image-uploader.image-instructions")}
          </Text>
        </Button>
      )}
    </FileButton>
  );
};

export default ImageUploader;

const HeaderIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" fill="#EDEFF3" />
      <rect x="2" y="4" width="6" height="5" fill="#4F3FA8" />
      <rect x="9" y="4" width="19" height="5" fill="#45B467" />
    </svg>
  );
};

export default HeaderIcon;

import { DefaultMantineColor, Text } from "@mantine/core";
import { ZammitComponent } from "@zammittech/components";
import { Search, UserCircle, ShoppingBag } from "tabler-icons-react";

import { INavigationComponent, ITheme } from "../../../typings/Theme";
import { ICompany } from "../../../typings/Company";

import ArabicIcon from "../../../assets/icons/ar.svg";
import { useNavigationByIds } from "../../../hooks/queries/useNavigationByIds";
import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { important } from "../../../utils/styles";

type Props = { company: ICompany; component: INavigationComponent; theme: ITheme };

const ComponentWithNavigationModel = ({ company, component, theme }: Props) => {
  const { isMobile, locale } = useBuilderQuery();
  const { data } = useNavigationByIds({
    component: component.key as "header" | "footer",
    ids: component.modelIds,
  });

  const txtColor = component.propValues.filter((propValue) => propValue.key === "txtColor")[0]
    .value as DefaultMantineColor;

  return component.key === "header" ? (
    <ZammitComponent
      sx={{ position: important("relative") }}
      company={company}
      component={{ ...component, data }}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
    >
      <div id="header-children" style={{ display: "flex", gap: 15 }}>
        <Search color={txtColor} />
        <ShoppingBag color={txtColor} />
      </div>
      <div id="aside-children" style={{ display: "flex", flexDirection: isMobile ? "column" : "row", gap: 15 }}>
        {isMobile ? (
          <>
            <Text color={txtColor}>Login</Text>
            <Text color={txtColor}>Create account</Text>
            <Text color={txtColor}>Arabic | English</Text>
          </>
        ) : (
          <>
            <UserCircle color={txtColor} />
            <img width={24} height={24} src={ArabicIcon} alt="language switcher" />
          </>
        )}
      </div>
    </ZammitComponent>
  ) : (
    <ZammitComponent
      company={company}
      component={{ ...component, data }}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
    />
  );
};

export default ComponentWithNavigationModel;

import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Header as MantineHeader, Group } from "@mantine/core";

import Button from "../../common/Button";
import ErrorModal from "../../common/ErrorModal";
import Breadcrumb from "./Breadcrumb";
import PageLayoutControls from "../components/PageLayoutControls";

import { useThemeUpdate } from "../../../hooks/mutations/useThemeUpdate";
import { useThemePublish } from "../../../hooks/mutations/useThemePublish";
import { ITheme } from "../../../typings/Theme";
import { isCurrentRoute } from "../../../utils";

type Props = { useForm?: boolean };

function Header({ useForm }: Props) {
  const { t } = useTranslation("common");

  // Only to be shown while the form is loading, so we can safely access the form context
  if (!useForm)
    return (
      <MantineHeader
        height={75}
        px={30}
        py={15}
        styles={(theme) => ({
          root: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            borderBottomColor: theme.colors["low-gray"][0],
          },
        })}
      >
        <Breadcrumb />
        {isCurrentRoute("sections") && <PageLayoutControls />}
        <Group spacing={20} w="20%" position="right">
          <Button mode="secondary" h={45}>
            {t("builder.header.save")}
          </Button>
          <Button mode="primary" h={45}>
            {t("builder.header.publish")}
          </Button>
        </Group>
      </MantineHeader>
    );

  const form = useFormContext<ITheme>();

  const saveTheme = useThemeUpdate();
  const publishTheme = useThemePublish();

  function onSave() {
    saveTheme.mutate({ theme: form.getValues() });
  }

  function onPublish() {
    publishTheme.mutate({ theme: form.getValues() });
  }

  return (
    <MantineHeader
      height={75}
      px={30}
      py={15}
      styles={(theme) => ({
        root: {
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          borderBottomColor: theme.colors["low-gray"][0],
        },
      })}
    >
      <Breadcrumb />

      {isCurrentRoute("sections") && <PageLayoutControls />}
      <Group spacing={20} w="20%" position="right">
        <Button
          mode="secondary"
          h={45}
          onClick={onSave}
          loading={saveTheme.isLoading}
          disabled={publishTheme.isLoading}
        >
          {t("builder.header.save")}
        </Button>
        <Button
          mode="primary"
          h={45}
          onClick={onPublish}
          loading={publishTheme.isLoading}
          disabled={saveTheme.isLoading}
        >
          {t("builder.header.publish")}
        </Button>
      </Group>

      <ErrorModal opened={saveTheme.isError} reset={saveTheme.reset} error={saveTheme.error} />
      <ErrorModal opened={publishTheme.isError} reset={publishTheme.reset} error={publishTheme.error} />
    </MantineHeader>
  );
}

export default Header;

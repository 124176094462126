import { useParams } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import { Box } from "@mantine/core";
import ColorsTab from "../../components/builder/settings/ColorsTab";
import ComponentsTab from "../../components/builder/settings/ComponentsTab";
import LogosTab from "../../components/builder/settings/LogosTab";
import TypographyTab from "../../components/builder/settings/TypographyTab";
import { SettingsTab } from "../../typings";
import { ITheme } from "../../typings/Theme";

const Settings = () => {
  const { activeTab } = useParams();
  const { control, watch, setValue } = useFormContext<ITheme>();

  const isActiveTab = (tab: SettingsTab) => {
    return activeTab === tab;
  };

  return (
    <Box m={30} w="auto">
      {isActiveTab("colors") && <ColorsTab control={control} />}
      {isActiveTab("typography") && <TypographyTab control={control} />}
      {isActiveTab("logos") && <LogosTab watch={watch} setValue={setValue} />}
      {isActiveTab("components") && <ComponentsTab control={control} />}
    </Box>
  );
};

export default Settings;

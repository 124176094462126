import { ICompany } from "../typings/Company";
import { IPage } from "../typings/Page";

export const formatInputName = ({
  name,
  pageIndex,
  locale,
}: {
  name: string;
  pageIndex?: number;
  locale?: "ar" | "en";
}) => {
  const inputName = locale ? `${name}${locale === "ar" ? "Ar" : "En"}` : name;
  return pageIndex !== undefined ? `pages.${pageIndex}.${inputName}` : inputName;
};

export const getShopUrl = (company?: ICompany) => {
  if (company?.customUrl) return company.customUrl;
  else if (company?.subdomain) return `${company.subdomain}.myzammit.shop`;
  else return "store.myzammit.shop";
};

export const formatPageHandle = (page: IPage) => {
  return page.isStatic
    ? page.handle
    : encodeURIComponent((page.nameEn || page.nameAr).replaceAll(" ", "-")).toLowerCase();
};

export const pagesWithGraphicOption = ["collection", "cart", "checkout-success", "sign-in", "sign-up"];

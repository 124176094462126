import { useTranslation } from "react-i18next";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { Box, Divider, Group, Text } from "@mantine/core";

import Tab from "./Tab";
import ImageUploader from "./ImageUploader";

import { ITheme } from "../../../typings/Theme";

const LogosTab = ({ watch, setValue }: { watch: UseFormWatch<ITheme>; setValue: UseFormSetValue<ITheme> }) => {
  const { t } = useTranslation("settings");

  return (
    <Tab tab="logos" title={t("logos.title")} desc={t("logos.description")}>
      <Box>
        <Text mb={20} size={16} weight={700} color="primary-black">
          {t("logos.logo.title")}
        </Text>
        <Group spacing={20}>
          <ImageUploader name="logo" imageUrl={watch("logoUrl")} setValue={setValue} />
          <Box>
            <Text mb={10} size={14} weight={400} color="dark-gray">
              {t(`logos.logo.${watch("logoUrl") ? "change" : "upload"}`)}
            </Text>
            <Text size={12} weight={300} color="med-gray">
              {t("logos.format_and_size")}
            </Text>
          </Box>
        </Group>
      </Box>
      <Divider my={20} color="light-gray" />
      <Box>
        <Text mb={20} size={16} weight={700} color="primary-black">
          {t("logos.favicon.title")}
        </Text>
        <Group spacing={20}>
          <ImageUploader name="favicon" imageUrl={watch("faviconUrl")} setValue={setValue} />
          <Box>
            <Text mb={10} size={14} weight={400} color="dark-gray">
              {t(`logos.favicon.${watch("faviconUrl") ? "change" : "upload"}`)}
            </Text>
            <Text size={12} weight={300} color="med-gray">
              {t("logos.format_and_size")}
            </Text>
          </Box>
        </Group>
      </Box>
    </Tab>
  );
};

export default LogosTab;

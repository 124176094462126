import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { SimpleGrid } from "@mantine/core";

import Tab from "./Tab";
import ColorPaletteCard from "../components/ColorPalette/ColorPaletteCard";

import { themeColors } from "../../../constants/settings";
import { ITheme } from "../../../typings/Theme";

const ColorsTab = ({ control }: { control: Control<ITheme, any> }) => {
  const { t } = useTranslation("settings");

  return (
    <Tab mode="grid" tab="colors" title={t("colors.title")} desc={t("colors.description")}>
      <SimpleGrid cols={3} mt={20}>
        <ColorPaletteCard group="text" colors={themeColors.text} control={control} />
        <ColorPaletteCard group="primary_btn" colors={themeColors.primaryBtn} control={control} />
        <ColorPaletteCard group="secondary_btn" colors={themeColors.secondaryBtn} control={control} />
        <ColorPaletteCard group="form_fields" colors={themeColors.formFields} control={control} />
        <ColorPaletteCard group="pages" colors={themeColors.pages} control={control} />
      </SimpleGrid>
    </Tab>
  );
};

export default ColorsTab;

import API from "./API";
import { apiPrefix } from "./constants";
import { ICollection, ICollectionProduct } from "../typings/Collection";
import { IMetadata } from "../typings";

type GetCollectionsResponse = {
  success: boolean;
  data: ICollection[];
  metadata: IMetadata;
};

type GetCollectionProductsResponse = {
  success: boolean;
  data: {
    products: ICollectionProduct[];
    metadata: IMetadata;
  };
};

export const getCollections = async (locale: "en" | "ar") => {
  const response = await API.get<GetCollectionsResponse>(`${apiPrefix}/collections`, {
    headers: { locale },
    params: { page: 1, limit: 1000 },
  });
  return response.data.data;
};

export const getCollectionProducts = async ({ id, locale }: { id: string; locale: "en" | "ar" }) => {
  const response = await API.get<GetCollectionProductsResponse>(`${apiPrefix}/collections/${id}/products`, {
    headers: { locale },
  });
  return response.data.data.products;
};

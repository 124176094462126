import { Control, useWatch } from "react-hook-form";
import { Slider } from "react-hook-form-mantine";
import { Group, Text } from "@mantine/core";

import { ITheme } from "../../../typings/Theme";
import { ThemeTextSize } from "../../../typings";

type Props = {
  title?: string;
  label: string;
  iconLabel: string;
  maxValue: number;
  name: ThemeTextSize;
  control: Control<ITheme>;
};

const TextSizeSlider = ({ title, label, iconLabel, maxValue, name, control }: Props) => {
  const value = useWatch({ control, name });

  return (
    <>
      {title && (
        <Text size={20} weight={400} color="primary-black" mb={25}>
          {title}
        </Text>
      )}
      <Group position="apart">
        <Group spacing={10}>
          <Group w={26} h={26} bg="light-gray" position="center" sx={{ borderRadius: 3 }}>
            <Text size={12} weight={400} color="high-gray">
              {iconLabel}
            </Text>
          </Group>
          <Text size={16} weight={400} color="high-gray">
            {label}
          </Text>
        </Group>
        <Group w="50%" position="right" spacing={25}>
          <Text size={16} weight={400} color="high-gray">
            Font size
          </Text>
          <Slider w="70%" size="xl" color="secondary-magenta" name={name} control={control} max={maxValue} min={5} />
          <Text size={12} weight={700} color="high-gray" w="40px">
            {value} px
          </Text>
        </Group>
      </Group>
    </>
  );
};

export default TextSizeSlider;

import API from "./API";
import { apiPrefix } from "./constants";

import { IPage } from "../typings/Page";

type PagesResponse = {
  success: boolean;
  data: IPage[];
};

type PageResponse = {
  success: boolean;
  data: IPage;
};

export const getPage = async ({ pageId, themeId }: { pageId: number; themeId: number }) => {
  const response = await API.get<PageResponse>(`${apiPrefix}/pages/${pageId}`, {
    headers: { locale: "all" },
    params: { theme_id: themeId },
  });
  return response.data.data;
};

export const getPages = async (themeId: number) => {
  const response = await API.get<PagesResponse>(`${apiPrefix}/pages`, {
    headers: { locale: "all" },
    params: { theme_id: themeId },
  });
  return response.data.data;
};

export const createPage = async ({ themeId, page }: { themeId: number; page: Partial<IPage> }) => {
  const response = await API.post<PageResponse>(`${apiPrefix}/pages`, page, {
    headers: { locale: "all" },
    params: { theme_id: themeId },
  });
  return response.data.data;
};

export const updatePage = async (page: Partial<IPage>) => {
  const response = await API.put<PageResponse>(`${apiPrefix}/pages/${page.id}`, page, { headers: { locale: "all" } });
  return response.data;
};

export const deletePage = async (id: number) => {
  const response = await API.delete<{ success: boolean }>(`${apiPrefix}/pages/${id}`);
  return response.data.success;
};

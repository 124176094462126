import React from "react";
import { useTranslation } from "react-i18next";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Group, Stack, Text } from "@mantine/core";

import ArrowButton from "../../common/ArrowButton";
import TreeView from "../treeView/TreeView";
import PropsConfig from "./PropsConfig";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { ITheme } from "../../../typings/Theme";

const ConfigSidebar = () => {
  const { i18n } = useTranslation();
  const { activePage, activeComponent, setActiveComponent } = useBuilderQuery();

  const form = useFormContext<ITheme>();
  const pageIndex = form.getValues("pages").findIndex((page) => page.id === activePage);
  const pageComponents = useFieldArray({
    name: `pages.${pageIndex}.pageComponents`,
    control: form.control,
    keyName: "formId",
  }).fields;

  const topComponents = useFieldArray({ name: "topComponents", control: form.control, keyName: "formId" }).fields;
  const bottomComponents = useFieldArray({ name: "bottomComponents", control: form.control, keyName: "formId" }).fields;

  if (!activeComponent) return <TreeView />;

  const { componentPrefix, componentIndex, component } = (() => {
    let component = null;
    let componentIndex = null;
    let componentPrefix:
      | "topComponents"
      | "bottomComponents"
      | `pages.${number}.pageComponents` = `pages.${pageIndex}.pageComponents`;

    const pageComponentIndex = pageComponents.findIndex((component) => component.id === activeComponent);

    if (pageComponentIndex !== -1) {
      componentIndex = pageComponentIndex;
      component = pageComponents[componentIndex];
    } else {
      const topComponentIndex = topComponents.findIndex((component) => component.id === activeComponent);

      if (topComponentIndex !== -1) {
        componentIndex = topComponentIndex;
        component = topComponents[componentIndex];
        componentPrefix = "topComponents";
      } else {
        componentIndex = bottomComponents.findIndex((component) => component.id === activeComponent);
        component = bottomComponents[componentIndex];
        componentPrefix = "bottomComponents";
      }
    }

    return { componentPrefix, componentIndex, component };
  })();

  if (!component) return <></>;

  return (
    <form>
      <Stack spacing={20} p={15}>
        <Group>
          <ArrowButton dir={i18n.language === "en" ? "left" : "right"} onClick={() => setActiveComponent(undefined)} />
          <Text fw={700}>{component.name}</Text>
        </Group>
        <PropsConfig
          pageIndex={pageIndex}
          component={component}
          componentPrefix={componentPrefix}
          componentIndex={componentIndex}
        />
      </Stack>
    </form>
  );
};

export default React.memo(ConfigSidebar);

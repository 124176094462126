import { useTranslation } from "react-i18next";
import { Badge, Card, Group, Stack, Text } from "@mantine/core";

import ActionsGroup from "./ActionsGroup";

import { ITheme } from "../../../typings/Theme";

const ActiveThemeCard = ({ theme }: { theme: ITheme }) => {
  const { t } = useTranslation("home");

  return (
    <Card
      px={30}
      py={20}
      h={220}
      radius={5}
      withBorder
      bg="primary-green"
      sx={{ boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)" }}
    >
      <Stack align="flex-start" spacing={20}>
        <Text h={29} size={24} weight={700} color="white">
          {theme.name}
        </Text>
        <Group>
          <Text size={16} weight={300} color="white">
            {t("theme_library.last_updated", { date: new Date(theme.updatedAt).toDateString() })}
          </Text>
          {theme.hasUnpublishedChanges ? (
            <Badge variant="filled" radius={5} h={27} bg="orange">
              {t("theme_library.pending_badge")}
            </Badge>
          ) : null}
        </Group>
        <ActionsGroup isActiveTheme={true} theme={theme} />
      </Stack>
    </Card>
  );
};

export default ActiveThemeCard;

import { useTranslation } from "react-i18next";
import { Loader } from "@mantine/core";

import MultiSelect from "../../../common/Multiselect";
import EditorLabel from "./EditorLabel";

import { useNavigation } from "../../../../hooks/queries/useNavigation";
import { ITheme } from "../../../../typings/Theme";
import { useFormContext } from "react-hook-form";

type Props = {
  prefix: `topComponents.${number}` | `bottomComponents.${number}`;
};

const NavigationDropdown = ({ prefix }: Props) => {
  const { t } = useTranslation("sections");
  const form = useFormContext<ITheme>();
  const { isLoading, data: navigation } = useNavigation();

  return (
    <MultiSelect
      searchable
      label={<EditorLabel label={t("config-sidebar.select-navigations")} />}
      rightSection={isLoading ? <Loader size={25} /> : undefined}
      data={
        navigation
          ?.filter(({ id, navigationable }) => navigationable.name && id)
          .map(({ id, navigationable }) => ({ label: navigationable.name, value: String(id) })) || []
      }
      control={form.control}
      name={`${prefix}.modelIds`}
    />
  );
};

export default NavigationDropdown;

import { useTranslation } from "react-i18next";
import { Link, useMatches } from "react-router-dom";
import { Group, Breadcrumbs, Text } from "@mantine/core";

import ArrowButton from "../../common/ArrowButton";

type Match = { id: string; pathname: string; handle?: { crumb?: string } };

const Breadcrumb = () => {
  const { t, i18n } = useTranslation("common");

  const matches = useMatches() as Match[];
  const crumbs = matches.filter((match) => Boolean(match.handle?.crumb));
  const isActive = (index: number) => index === crumbs.length - 1;

  return (
    <Group spacing={15}>
      <Link to="/">
        <ArrowButton dir={i18n.language === "en" ? "left" : "right"} />
      </Link>
      <Breadcrumbs>
        {crumbs.map((item, index) => (
          <Text
            key={item.id}
            size={16}
            weight={400}
            color={isActive(index) ? "primary-black" : "high-gray"}
            component={Link}
            to={item}
          >
            {t(`breadcrumbs.${item.handle?.crumb}`)}
          </Text>
        ))}
      </Breadcrumbs>
    </Group>
  );
};

export default Breadcrumb;

import { useTranslation } from "react-i18next";
import { Divider, Drawer, Text, Box, LoadingOverlay, Flex, Image, Anchor } from "@mantine/core";

import SectionTemplateCard from "./SectionTemplateCard";
import Button from "../../common/Button";
import AddSection from "./AddSection";
import customComponent from "../../../assets/custom-component.png";

import { useSectionTemplates } from "../../../hooks/queries/useSectionTemplates";
import { useAddThemeSection } from "../../../hooks/mutations/useAddThemeSection";
import { important } from "../../../utils/styles";
import { ComponentDisplay } from "../../../typings/Theme";

const AddSectionDrawer = ({
  componentDisplay,
  opened,
  close,
}: {
  componentDisplay: ComponentDisplay;
  opened: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation("sections");
  const { data: sectionTemplates } = useSectionTemplates(opened);
  const { mutate: addThemeSection, isLoading: isAddThemeSectionLoading } = useAddThemeSection({
    onSuccess: close,
  });

  return (
    <>
      <LoadingOverlay visible={isAddThemeSectionLoading} zIndex={1003} />
      <Drawer
        opened={opened}
        onClose={close}
        position="right"
        size="75%"
        withCloseButton={false}
        styles={{
          inner: {
            zIndex: 1002,
          },
          overlay: {
            zIndex: 1001,
          },
          content: {
            maxWidth: important("60%"),
            borderRadius: "10px 0 0 10px !important",
          },
          body: {
            padding: 50,
          },
        }}
      >
        <Text size={30} color="primary-black">
          {t("section-drawer.title")}
        </Text>
        <Text weight={300}>{t("section-drawer.subtitle")}</Text>
        {sectionTemplates?.map((sectionTemplate) => (
          <Box key={sectionTemplate.id}>
            <Divider mt={20} mb={20} />
            <SectionTemplateCard
              section={sectionTemplate}
              componentDisplay={componentDisplay}
              addThemeSection={addThemeSection}
            />
          </Box>
        ))}
        <Divider mt={20} mb={20} />
        <ComponentCard componentDisplay={componentDisplay} />
      </Drawer>
    </>
  );
};

export default AddSectionDrawer;

const ComponentCard = ({ componentDisplay }: { componentDisplay: ComponentDisplay }) => {
  const { t } = useTranslation("sections");

  return (
    <Flex gap={20}>
      <Image src={customComponent} alt="Add Component" height={150} width={300} fit="contain" />
      <Flex direction="column" gap={15}>
        <Text weight={700} size={18} color="primary-black">
          {t("section-drawer.custom-section-title")}
        </Text>
        <Text weight={300} size={16} color="primary-black">
          {t("section-drawer.custom-section-description")}
        </Text>
        <Box h="100%" sx={{ display: "flex", alignItems: "flex-end" }}>
          <Button mode="primary">
            <AddSection
              componentDisplay={componentDisplay}
              target={(isLoading) => (
                <Anchor color="white" underline={false}>
                  {isLoading ? t("tree-view.loading") : t("tree-view.add-component")}
                </Anchor>
              )}
            />
          </Button>
        </Box>
      </Flex>
    </Flex>
  );
};

import { ReactNode } from "react";
import { Box } from "@mantine/core";

import Banner from "./Banner";
import Section from "../../common/Section";

import { SettingsTab } from "../../../typings";

const Tab = ({
  mode = "section",
  tab,
  title,
  desc,
  children,
}: {
  mode?: "section" | "grid";
  title: string;
  desc: string;
  children: ReactNode;
  tab: SettingsTab;
}) => {
  return (
    <Box>
      <Banner title={title} desc={desc} tab={tab} />
      {mode === "section" && <Section mt={20}>{children}</Section>}
      {mode === "grid" && <>{children}</>}
    </Box>
  );
};

export default Tab;

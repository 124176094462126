// consider using framer motion for animations here

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, ActionIcon, Divider, Group, SegmentedControl, Stack, Text } from "@mantine/core";

import CollectionsDropdown from "./propEditors/CollectionsDropdown";
import NavigationDropdown from "./propEditors/NavigationDropdown";
import Dropdown from "../../common/Dropdown";
import ArrowButton from "../../common/ArrowButton";
import PropEditor from "./PropEditor";

import { ComponentPrefix, IThemeComponent } from "../../../typings/Theme";
import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useDeleteThemeComponent } from "../../../hooks/mutations/useDeleteThemeComponent";

import GarbageCan from "../../icons/GarbageCan";

type Tab = "main" | "details";

type InternalProps = {
  isSmartComponent?: boolean;
  pageIndex: number;
  component: IThemeComponent;
  prefix:
    | `${ComponentPrefix}.${number}`
    | `${ComponentPrefix}.${number}.children.${number}`
    | `${ComponentPrefix}.${number}.children.${number}.children.${number}`;
  locale: "en" | "ar";
};

const ChildrenAccordion = ({ pageIndex, component, prefix, locale }: InternalProps) => {
  const { mutate: deleteComponent, isLoading } = useDeleteThemeComponent();

  if (!component.children?.length) return <></>;
  return (
    <Accordion
      chevron={<ArrowButton dir="down" />}
      defaultValue={String(component.children[0].id)}
      chevronPosition="left"
      variant="filled"
      styles={(theme) => ({
        content: { padding: "1rem 0" },
        control: { paddingX: 10, backgroundColor: theme.colors["light-gray"] },
        item: { "&[data-active]": { backgroundColor: "transparent" } },
      })}
    >
      {component.children.map((child, childIndex) => (
        <Accordion.Item key={child.id} value={String(child.id)} mb={10}>
          <Accordion.Control>
            <Group position="apart" align="center" noWrap>
              <Text transform="capitalize" weight={500}>
                {child.name}
              </Text>

              <ActionIcon
                variant="subtle"
                onClick={() => deleteComponent({ id: child.id, pageIndex, display: component.display })}
                loading={isLoading}
              >
                <GarbageCan stroke="red" />
              </ActionIcon>
            </Group>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack spacing={20} px={4}>
              {child.propValues.map((prop, propIndex) => {
                if (prop.locale && prop.locale !== locale) return null;

                return (
                  <PropEditor
                    key={`${component.id}-${prop.key}-${prop.locale}`}
                    prop={prop}
                    name={
                      // TS Hack as RHF has issues with circular (recursive) types, should be fixed in v8 as per GH
                      `${prefix}.children.${childIndex}.propValues.${propIndex}.value` as never
                    }
                  />
                );
              })}
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

type SimpleEditorProps = InternalProps & { displayChildren?: boolean };
const SimpleEditor = ({ pageIndex, component, prefix, locale, displayChildren }: SimpleEditorProps) => {
  return (
    <>
      {component.model &&
        (component.model === "Collection" ? (
          <CollectionsDropdown prefix={prefix as `pages.${number}.pageComponents.${number}`} />
        ) : (
          <NavigationDropdown prefix={prefix as `${"topComponents" | "bottomComponents"}.${number}`} />
        ))}
      {component.propValues.map((prop, propIndex) => {
        if (prop.locale && prop.locale !== locale) return null;

        return (
          <PropEditor
            key={`${component.id}-${prop.key}-${prop.locale}`}
            prop={prop}
            name={`${prefix}.propValues.${propIndex}.value` as never}
          />
        );
      })}
      {displayChildren && (
        <>
          <Divider />
          <ChildrenAccordion pageIndex={pageIndex} component={component} prefix={prefix} locale={locale} />
        </>
      )}
    </>
  );
};

const ComplexEditor = ({ pageIndex, component, prefix, locale }: InternalProps) => {
  const { t } = useTranslation("common");
  const [currentTab, setCurrentTab] = useState<Tab>("main");
  const [selectedChildId, setSelectedChildId] = useState<number | undefined>(component.children?.[0].id);
  const { mutate: deleteComponent, isLoading } = useDeleteThemeComponent(() =>
    setSelectedChildId(component.children?.[0]?.id),
  );

  if (!component.children?.length) return <></>;

  const selectedChildIndex = component.children.findIndex((child) => child.id === selectedChildId);
  const selectedChild = component.children[selectedChildIndex];

  return (
    <>
      <SegmentedControl
        value={currentTab}
        onChange={(value) => setCurrentTab(value as Tab)}
        data={[
          { value: "main", label: t("main") },
          { value: "details", label: t("details") },
        ]}
        color="dark-gray"
      />
      {currentTab === "main" ? (
        <SimpleEditor pageIndex={pageIndex} component={component} prefix={prefix} locale={locale} />
      ) : (
        <>
          <Group>
            <Dropdown
              data={component.children.map((child) => ({
                value: String(child.id),
                label: child.name,
              }))}
              value={String(selectedChildId)}
              onChange={(value) => setSelectedChildId(Number(value))}
              sx={{ flexGrow: 1 }}
            />
            <ActionIcon
              variant="subtle"
              onClick={() => deleteComponent({ id: selectedChild.id, pageIndex, display: component.display })}
              loading={isLoading}
            >
              <GarbageCan stroke="red" />
            </ActionIcon>
          </Group>
          {selectedChild && (
            <SimpleEditor
              pageIndex={pageIndex}
              component={selectedChild}
              prefix={`${prefix}.children.${selectedChildIndex}` as never}
              locale={locale}
              displayChildren
            />
          )}
        </>
      )}
    </>
  );
};

type Props = {
  pageIndex: number;
  componentPrefix: ComponentPrefix;
  componentIndex: number;
  component: IThemeComponent;
};
const PropsConfig = ({ pageIndex, componentPrefix, componentIndex, component }: Props) => {
  const hasComplexChildren = component.children?.some((child) => !child.isBasic);

  const { locale } = useBuilderQuery();
  const editorProps: InternalProps = { pageIndex, component, locale, prefix: `${componentPrefix}.${componentIndex}` };

  return hasComplexChildren ? <ComplexEditor {...editorProps} /> : <SimpleEditor {...editorProps} displayChildren />;
};

export default PropsConfig;

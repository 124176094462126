import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Group, Modal, Stack } from "@mantine/core";

import PageHeader from "./PageHeader";
import PageSettings from "./PageSettings";
import PageSEO from "./PageSEO";
import Button from "../../common/Button";

import { useCreatePage } from "../../../hooks/mutations/useCreatePage";
import { IPage } from "../../../typings/Page";
import { important } from "../../../utils/styles";
import { formatPageHandle } from "../../../utils/pages";

const AddPageModal = ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => {
  const { t } = useTranslation("pages");
  const params = useParams();

  const { control, setValue, getValues, reset, watch } = useForm<IPage>({
    defaultValues: {
      nameAr: "",
      nameEn: "",
      descriptionAr: "",
      descriptionEn: "",
      handle: "",
      isHidden: false,
      isStatic: false,
      seoDescriptionAr: "",
      seoDescriptionEn: "",
      seoTitleAr: "",
      seoTitleEn: "",
    },
  });
  const page = watch();

  const onClose = () => {
    closeModal();
    reset();
  };

  const { mutate: createPage, isLoading: isCreatePageLoading } = useCreatePage(onClose);

  const onSave = () => {
    const themeId = Number(params.theme);
    const values = getValues();
    createPage({
      themeId,
      page: {
        ...values,
        handle: values.handle || formatPageHandle(values),
        seoTitleAr: values.seoTitleAr || values.nameAr,
        seoTitleEn: values.seoTitleEn || values.nameEn,
        seoDescriptionAr: values.seoDescriptionAr || values.descriptionAr,
        seoDescriptionEn: values.seoDescriptionEn || values.descriptionEn,
      },
    });
  };

  return (
    <Modal
      styles={{ overlay: { zIndex: important(1000) }, inner: { zIndex: important(1000) } }}
      size="50%"
      opened={isOpen}
      onClose={onClose}
    >
      <Stack spacing={20}>
        <PageHeader page={page} />
        <PageSettings page={page} control={control} setValue={setValue} />
        <PageSEO page={page} control={control} />
        <Group position="right" spacing={20}>
          <Button mode="secondary" h={45} onClick={closeModal} disabled={isCreatePageLoading}>
            {t("cancel")}
          </Button>
          <Button mode="primary" h={45} onClick={onSave} loading={isCreatePageLoading}>
            {t("save")}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default AddPageModal;

import { BoxMargin } from "tabler-icons-react";
import { ComponentKey } from "../../../typings/Theme";

import AnnouncementBarIcon from "../../icons/AnnouncementBarIcon";
import FeaturedCollectionIcon from "../../icons/FeaturedCollectionIcon";
import FooterIcon from "../../icons/FooterIcon";
import FreeTextIcon from "../../icons/FreeTextIcon";
import HeaderIcon from "../../icons/HeaderIcon";
import HeroBannerIcon from "../../icons/HeroBannerIcon";

type IComponentIconMap = {
  [key in ComponentKey]: JSX.Element;
};

export const ComponentIconMap: IComponentIconMap = {
  // TODO: add missing icons
  announcement_bar: <AnnouncementBarIcon />,
  header: <HeaderIcon />,
  free_text: <FreeTextIcon />,
  hero_banner: <HeroBannerIcon />,
  hero_banner_carousel: <BoxMargin size={24} />,
  cards_carousel: <BoxMargin size={24} />,
  cards_grid: <BoxMargin size={24} />,
  featured_collection: <FeaturedCollectionIcon />,
  footer: <FooterIcon />,
  card: <BoxMargin size={24} />,
  button: <BoxMargin size={24} />,
};

import { useTranslation } from "react-i18next";
import { UseFormSetValue } from "react-hook-form";
import { Button, FileButton, useMantineTheme } from "@mantine/core";

import ImagePlaceholder from "../../../assets/image-placeholder.svg";
import ColoredDotsLoader from "../../animations/ColoredDotsLoader.gif";

import { useImageUpload } from "../../../hooks/mutations/useImageUpload";
import { ITheme } from "../../../typings/Theme";

type Props = {
  name: "logo" | "favicon";
  imageUrl?: string;
  setValue: UseFormSetValue<ITheme>;
};

const ImageUploader = ({ name, imageUrl, setValue }: Props) => {
  const theme = useMantineTheme();
  const { t } = useTranslation("settings");

  const { mutate: mutateImageUpload, isLoading } = useImageUpload();

  const onChange = (image: File | null) => {
    if (image) {
      mutateImageUpload({
        image,
        onSuccess({ id, url }) {
          setValue(`${name}Id`, id);
          setValue(`${name}Url`, url);
        },
      });
    } else {
      setValue(`${name}Id`, undefined);
      setValue(`${name}Url`, undefined);
    }
  };

  return (
    <FileButton onChange={onChange} accept="image/png,image/jpeg">
      {(props) => (
        <Button
          px={isLoading || imageUrl ? 0 : 15}
          py={isLoading || imageUrl ? 0 : 18}
          styles={() => ({
            root: {
              width: 100,
              height: 100,
              color: theme.colors["dark-gray"][0],
              border: "1px dashed #CDCCD7",
              backgroundColor: theme.colors["light-gray"][0],

              "&:hover": {
                color: theme.colors["dark-gray"][0],
                border: "1px dashed #CDCCD7",
                backgroundColor: theme.colors["light-gray"][0],
              },
            },
            label: {
              fontSize: 14,
              fontWeight: 400,
              lineHeight: 1.2,
              flexDirection: "column",
              justifyContent: "space-between",
            },
          })}
          {...props}
        >
          {isLoading ? (
            <img width="100%" height="100%" src={ColoredDotsLoader} alt="loading" />
          ) : imageUrl ? (
            <img width="100%" height="100%" src={imageUrl} alt={name} />
          ) : (
            <>
              <img width={30} height={30} src={ImagePlaceholder} alt="upload image" />
              {t(`logos.${name}.placeholder`)}
            </>
          )}
        </Button>
      )}
    </FileButton>
  );
};

export default ImageUploader;

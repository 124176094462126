import { useState } from "react";
import ArrowButton from "./ArrowButton";
import { SelectProps as FormSelectProps, Select as FormSelect } from "react-hook-form-mantine";
import { SelectProps as NormalSelectProps, Select as NormalSelect } from "@mantine/core";
import { ITheme } from "../../typings/Theme";
import { Control } from "react-hook-form";

// Pro gamer move incoming
type FormProps = { control: Control<ITheme> } & FormSelectProps<ITheme>;
type NormalProps = { control?: undefined; name?: undefined } & NormalSelectProps;

type Props = FormProps | NormalProps;

const Dropdown = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const delay = 300;

  const selectProps: Props = {
    ...props,
    onDropdownOpen: () => setIsOpen(true),
    onDropdownClose: () => setIsOpen(false),
    variant: "filled",
    rightSection: props.rightSection ? props.rightSection : <ArrowButton dir="down" />,
    transitionProps: {
      transition: "fade",
      duration: delay,
      timingFunction: "ease-in-out",
    },
    styles: (theme) => ({
      root: {
        width: props.width,
      },
      rightSection: {
        transition: `transform ${delay}ms ease-in-out`,
        transform: isOpen ? "rotate(180deg)" : "",
      },
      input: {
        "&:focus": { borderColor: "transparent" },
      },
      item: {
        "&[data-selected]": {
          "&, &:hover": {
            backgroundColor: theme.colors["secondary-magenta"][0],
          },
        },
      },
    }),
  };

  if (selectProps.control) return <FormSelect {...selectProps} />;
  else return <NormalSelect {...selectProps} />;
};

export default Dropdown;

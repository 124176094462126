import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { Text } from "@mantine/core";
import ColorInput from "../ColorInput";
import { ITheme } from "../../../../typings/Theme";
import { ThemeColors } from "../../../../typings";

const ColorPaletteItem = ({
  name,
  group,
  control,
}: {
  name: ThemeColors;
  group: "text" | "primary_btn" | "secondary_btn" | "form_fields" | "pages";
  control: Control<ITheme>;
}) => {
  const { t } = useTranslation("settings");

  return (
    <ColorInput
      label={
        <Text size={14} weight={300} color="primary-black">
          {t(`colors.${group}.${name}`)}
        </Text>
      }
      control={control}
      name={name}
      showColorPreview
    />
  );
};

export default ColorPaletteItem;

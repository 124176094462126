import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";

import { ITheme } from "../../typings/Theme";
import { deletePage } from "../../api/Pages";

export const useDeletePage = () => {
  const navigate = useNavigate();
  const form = useFormContext<ITheme>();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => deletePage(id),
    onSuccess(_, id) {
      const pages = form.getValues("pages");

      const index = pages.findIndex((page) => page.id === id);
      pages.splice(index, 1);
      form.setValue("pages", pages);

      const themeId = form.getValues("id");
      queryClient.invalidateQueries(["pages", themeId]);
      navigate(`../${pages[0].id}`, { relative: "path" });
    },
  });
};

const FeaturedCollectionIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" fill="#EDEFF3" />
      <rect x="2" y="4" width="6" height="6" fill="#D47B21" />
      <rect x="12" y="4" width="6" height="6" fill="#D47B21" />
      <rect x="22" y="4" width="6" height="6" fill="#D47B21" />
      <rect x="2" y="13" width="6" height="6" fill="#D47B21" />
      <rect x="12" y="13" width="6" height="6" fill="#D47B21" />
      <rect x="22" y="13" width="6" height="6" fill="#D47B21" />
      <rect x="2" y="22" width="6" height="6" fill="#D47B21" />
      <rect x="12" y="22" width="6" height="6" fill="#D47B21" />
      <rect x="22" y="22" width="6" height="6" fill="#D47B21" />
    </svg>
  );
};

export default FeaturedCollectionIcon;

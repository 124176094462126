import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";
import { TextInput, Textarea } from "react-hook-form-mantine";
import { Box, Divider, Group, Stack, Text, Title } from "@mantine/core";

import Section from "../../common/Section";
import InputLabel from "./InputLabel";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useCompany } from "../../../hooks/queries/useCompany";
import { useTheme } from "../../../hooks/queries/useTheme";
import { formatInputName, formatPageHandle, getShopUrl } from "../../../utils/pages";
import { ITheme } from "../../../typings/Theme";
import { IPage } from "../../../typings/Page";

type Props = {
  pageIndex?: number;
  page: IPage;
  control: Control<ITheme> | Control<IPage>;
};

const PageSEO = ({ pageIndex, page, control }: Props) => {
  const { t } = useTranslation("pages");
  const { locale } = useBuilderQuery();
  const params = useParams();

  const { data: company } = useCompany();

  const themeId = Number(params.theme);
  const { data: theme } = useTheme(themeId);

  const seoTitle = locale === "ar" ? page.seoTitleAr : page.seoTitleEn;
  const newPageSeoTitle = locale === "ar" ? page.seoTitleAr || page.nameAr : page.seoTitleEn || page.nameEn;

  const seoDesc = locale === "ar" ? page.seoDescriptionAr : page.seoDescriptionEn;
  const newPageSeoDesc =
    locale === "ar" ? page.seoDescriptionAr || page.descriptionAr : page.seoDescriptionEn || page.descriptionEn;

  return (
    <Section>
      <Text size={18} weight={500} mb={10}>
        {t("seo.title")}
      </Text>
      <Text size={14} weight={300} mb={20}>
        {t("seo.desc")}
      </Text>
      <Box
        px={30}
        py={20}
        sx={{
          borderRadius: 10,
          border: "1px solid #CDCCD7",
        }}
      >
        <Group spacing={12}>
          <Box
            w={26}
            h={26}
            sx={{
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f1f3f4",
              border: "1px solid #ecedef",
              borderRadius: "50%",
            }}
          >
            <img width="18px" height="18px" src={theme?.logoUrl} alt={`${company?.name} logo`} />
          </Box>
          <Box>
            <Text sx={{ color: "#202124", whiteSpace: "nowrap" }} size={14} maw={200}>
              {company?.name}
            </Text>
            <Text sx={{ color: "#4d5156", whiteSpace: "nowrap", overflow: "hidden" }} size={12}>
              {`${getShopUrl(company)} › ${formatPageHandle(page)}`}
            </Text>
          </Box>
        </Group>
        <Title
          sx={{ color: "#1a0dab", lineHeight: 1.3, fontFamily: "arial, sans-serif" }}
          order={3}
          size={20}
          weight={400}
          my={3}
        >
          {page.handle !== "index"
            ? `${page.id ? seoTitle : newPageSeoTitle} | ${company?.name}`
            : page.id
            ? seoTitle
            : newPageSeoTitle}
        </Title>
        <Text sx={{ color: "#4d5156" }} size={14}>
          {page.id ? seoDesc : newPageSeoDesc}
        </Text>
      </Box>
      <Divider my={20} color="light-gray" />
      <Stack spacing={20}>
        <Box w="100%">
          <InputLabel label={t("seo.seo-title")} withLocale />

          {page.id ? (
            <TextInput
              size="md"
              name={formatInputName({ name: "seoTitle", pageIndex, locale }) as keyof ITheme}
              control={control as Control<ITheme>}
            />
          ) : (
            <TextInput
              size="md"
              name={formatInputName({ name: "seoTitle", locale }) as keyof IPage}
              control={control as Control<IPage>}
              placeholder={locale === "ar" ? page.nameAr : page.nameEn}
            />
          )}
        </Box>
        <Box w="100%">
          <InputLabel label={t("seo.seo-desc")} withLocale />

          {page.id ? (
            <Textarea
              autosize
              minRows={3}
              name={formatInputName({ name: "seoDescription", pageIndex, locale }) as keyof ITheme}
              control={control as Control<ITheme>}
            />
          ) : (
            <Textarea
              autosize
              minRows={3}
              name={formatInputName({ name: "seoDescription", locale }) as keyof IPage}
              control={control as Control<IPage>}
              placeholder={locale === "ar" ? page.descriptionAr : page.descriptionEn}
            />
          )}
        </Box>
      </Stack>
    </Section>
  );
};

export default PageSEO;

import { useMutation } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";

import { createThemeComponent } from "../../api/Theme";
import { ComponentDisplay, ITheme } from "../../typings/Theme";

type ThemeComponentsKey = "topComponents" | "bottomComponents" | `pages.${number}.pageComponents`;

type Params = {
  themeId: number;
  page?: { id: number; index: number };
  parentId?: number;
  componentId: number;
  display: ComponentDisplay;
};

export const useCreateThemeComponent = () => {
  const form = useFormContext<ITheme>();

  return useMutation({
    mutationFn: ({ page, ...params }: Params) => createThemeComponent({ pageId: page?.id, ...params }),
    onSuccess(newComponent, { page, parentId, display }) {
      const themeComponentsKey = (
        page ? `pages.${page.index}.pageComponents` : `${display}Components`
      ) as ThemeComponentsKey;
      const themeComponents = form.getValues(themeComponentsKey);

      if (parentId) {
        const childIndex = themeComponents!.findIndex((component) => component.id === parentId);
        if (childIndex !== -1) themeComponents![childIndex].children?.push(newComponent);
        else {
          themeComponents!.forEach((component) => {
            if (!component.children || component.children.length === 0) return;

            const childIndex = component.children.findIndex((child) => child.id === parentId);
            if (childIndex !== -1) component.children[childIndex].children?.push(newComponent);
          });
        }
      } else {
        themeComponents!.push(newComponent);
      }

      form.setValue(themeComponentsKey, themeComponents);
    },
  });
};

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Card, Image, Text, Group, Box, Flex } from "@mantine/core";

import Tag from "./Tag";
import Button from "../../common/Button";

import { useCreateTheme } from "../../../hooks/mutations/useCreateTheme";
import { IStoreTemplate } from "../../../typings/StoreTemplate";
import { Carousel } from "@mantine/carousel";

const StoreTemplateCard = ({ storeTemplate }: { storeTemplate: IStoreTemplate }) => {
  const { t } = useTranslation("home");
  const { mutate: createTheme, isLoading: isCreateThemeLoading } = useCreateTheme();

  const navigate = useNavigate();

  const selectTheme = () => {
    createTheme({
      storeTemplateId: storeTemplate.id,
      onSuccess: (id) => navigate(`/builder/${id}/sections`),
    });
  };

  const tags = storeTemplate.industryTags.concat(storeTemplate.featuresTags);
  if (storeTemplate.catalogSize !== null) tags.push(storeTemplate.catalogSize);

  return (
    <Card
      style={{
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
        width: 322,
        minHeight: 400,
      }}
    >
      <Card.Section>
        <Carousel height={220} withControls={storeTemplate.thumbnails.length > 1}>
          {storeTemplate.thumbnails.map((thumbnail) => (
            <Carousel.Slide key={thumbnail}>
              <Image src={thumbnail} alt={storeTemplate.name} height={220} />
            </Carousel.Slide>
          ))}
        </Carousel>
      </Card.Section>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <Group position="apart" mt="15px">
          <Text size="md" fw={700} lh="19px">
            {storeTemplate.name}
          </Text>
          <Text size="md" fw={700} lh="19px">
            {storeTemplate.price == 0 ? "Free" : "EGP " + storeTemplate.price}
          </Text>
        </Group>

        <Text size="sm" fw={300} align="left" color="high-gray" lh="15px">
          {t("themes_store.by", { author: storeTemplate.author })}
        </Text>

        <Group py={10} spacing={10}>
          {/* Displaying the first 3 tags and add a tag for the rest ex. "+3" */}
          {tags.slice(0, 3).map((tag: string, index: number) => (
            <Tag name={tag} key={index} />
          ))}

          {tags.length > 3 && <Tag name={`+${tags.length - 3}`} />}
        </Group>

        <Flex justify="space-between">
          <Button mode={"primary"} h={45} fullWidth onClick={selectTheme} loading={isCreateThemeLoading}>
            {t("themes_store.create")}
          </Button>
          {/* TODO: uncomment after implementing preview logic */}
          {/* <Button mode={"secondary"} h={45} w={93}>
            <a
              href={storeTemplate.previewUrl}
              target="_blank"
              style={{ textDecoration: "none", color: "inherit" }}
              rel="noreferrer"
            >
              {t("themes_store.preview")}
            </a>
          </Button> */}
        </Flex>
      </Box>
    </Card>
  );
};

export default StoreTemplateCard;

import { useTranslation } from "react-i18next";
import { createStyles, Box, Group, Text, List, Anchor } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import i18n from "i18next";

import ArrowButton from "../../common/ArrowButton";
import { ComponentIconMap } from "../components/ComponentIconMap";
import DragIcon from "../../icons/DragIcon";

import { IThemeComponent } from "../../../typings/Theme";
import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import AddSection from "../sidebar/AddSection";

type ComponentItemProps = {
  component: IThemeComponent;
};

function ChildrenList({ component }: ComponentItemProps) {
  const useStyles = createStyles((theme) => ({
    startDot: {
      position: "absolute",
      width: "6px",
      height: "6px",
      left: "-2px",
      background: theme.colors["low-gray"][0],
      borderRadius: "50%",
    },

    endTriangle: {
      position: "absolute",
      borderTop: "4px solid transparent",
      borderLeft: `8px solid ${theme.colors["low-gray"][0]}`,
      borderBottom: "4px solid transparent",
      width: 0,
      height: 0,
      left: "14px",
      top: "37%",
    },

    innerChildItem: {
      position: "relative",
      padding: "5px 30px",
      borderLeft: "2px solid",
      borderLeftColor: theme.colors["low-gray"][0],
    },

    childItem: {
      position: "relative",
      padding: "5px 30px",
      borderLeft: "2px solid",
      borderLeftColor: theme.colors["low-gray"][0],
      listStyle: "none",

      "&::before": {
        content: '""',
        position: "absolute",
        borderBottom: "2px solid",
        borderBottomColor: theme.colors["low-gray"][0],
        top: "45%",
        left: 0,
        width: "15px",
      },

      "&:last-of-type": {
        border: "none",

        "&::after": {
          content: '""',
          position: "absolute",
          borderLeft: "2px solid",
          borderLeftColor: theme.colors["low-gray"][0],
          top: 0,
          left: 0,
          height: "45%",
        },
      },
    },
  }));
  const { classes } = useStyles();
  const { t } = useTranslation("sections");

  return (
    <List ml={14} mt="sm" pos="relative">
      {!component.isBasic && <span className={classes.startDot} />}
      {component.children?.map((child) => (
        <>
          <List.Item key={child.id} icon={ComponentIconMap[child.key]} className={classes.childItem}>
            <span className={classes.endTriangle} />
            <Text size={15}>{child.name}</Text>
          </List.Item>
          {child.children && child.children.length > 0 && (
            <Box className={classes.innerChildItem}>
              <ChildrenList component={child} />
            </Box>
          )}
        </>
      ))}
      {!component.isBasic && (
        <List.Item className={classes.childItem}>
          <span className={classes.endTriangle} />
          <AddSection
            componentDisplay={component.display}
            parentComponent={component}
            target={(isLoading) => <Anchor>{isLoading ? t("tree-view.loading") : t("tree-view.add-component")}</Anchor>}
          />
        </List.Item>
      )}
    </List>
  );
}

function ComponentItem({ component }: ComponentItemProps) {
  const { hovered, ref } = useHover();
  const { setActiveComponent } = useBuilderQuery();

  function selectComponent() {
    document.getElementById(String(component.id))?.scrollIntoView({ behavior: "smooth" });
    setActiveComponent(component.id);
  }

  const icon = hovered ? <DragIcon /> : ComponentIconMap[component.key];

  return (
    <Box sx={{ cursor: "pointer" }}>
      <Group position="apart" onClick={selectComponent} ref={ref}>
        {icon}
        <Text size={15} weight={500} color="primary-black" style={{ flexGrow: 1 }}>
          {component.name}
        </Text>
        <ArrowButton dir={i18n.language === "en" ? "right" : "left"} />
      </Group>
      {component.children && component.children?.length > 0 ? <ChildrenList component={component} /> : null}
    </Box>
  );
}

export default ComponentItem;

const FreeTextIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" fill="#EDEFF3" />
      <rect x="2" y="20" width="26" height="3" fill="#CDCCD7" fillOpacity="0.5" />
      <rect x="2" y="25" width="22" height="3" fill="#CDCCD7" fillOpacity="0.5" />
      <rect x="2" y="15" width="16" height="3" fill="#CDCCD7" fillOpacity="0.5" />
      <path d="M2 5.53125V4H8.07927V5.53125H5.97544V11H4.10384V5.53125H2Z" fill="#404C60" />
      <path
        d="M8.79393 11V4H13.8349V5.53125H10.6929V6.73438H13.5754V8.26562H10.6929V9.46875H13.8213V11H8.79393Z"
        fill="#404C60"
      />
      <path
        d="M16.8123 4L17.9598 6.03711H18.0145L19.1757 4H21.2932L19.203 7.5L21.3751 11H19.203L18.0145 8.92188H17.9598L16.7713 11H14.6128L16.7576 7.5L14.6811 4H16.8123Z"
        fill="#404C60"
      />
      <path d="M21.9207 5.53125V4H28V5.53125H25.8962V11H24.0246V5.53125H21.9207Z" fill="#404C60" />
    </svg>
  );
};

export default FreeTextIcon;

import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { List, createStyles, Text, Button, Stack, Box, Divider } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { NewSection } from "tabler-icons-react";

import AddSectionDrawer from "../sidebar/AddSectionDrawer";
import ComponentItem from "./ComponentItem";
import { StrictModeDroppable } from "./Droppable";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { ComponentDisplay, ITheme } from "../../../typings/Theme";

const useStyles = createStyles((theme) => ({
  listLayout: {
    listStyle: "none",
  },
  componentLayout: {
    borderLeft: "2px solid",
    borderLeftColor: theme.colors["low-gray"][0],
  },
}));

const TreeViewSection = ({
  title,
  controlName,
  componentDisplay,
}: {
  title: string;
  controlName: "topComponents" | "bottomComponents" | `pages.${number}.pageComponents`;
  componentDisplay: ComponentDisplay;
}) => {
  const { t } = useTranslation("sections");
  const { classes } = useStyles();

  const [isDrawerOpen, { open: openDrawer, close: closeDrawer }] = useDisclosure();

  const form = useFormContext<ITheme>();
  const components = useWatch({ name: controlName, control: form.control });

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const destIndex = result.destination.index;

    components!.splice(destIndex, 0, components!.splice(result.source.index, 1)[0]);

    components!.forEach((component, index) => (component.order = index));

    form.setValue(controlName, components!);
  };

  return (
    <Stack px={10}>
      <Stack px={10}>
        <Text size={18} weight={500} color="primary-black">
          {title}
        </Text>
        {components && components.length > 0 && (
          <DragDropContext onDragEnd={handleDragEnd}>
            <StrictModeDroppable droppableId="components">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={classes.listLayout}
                  spacing="md"
                  styles={{ itemWrapper: { width: "100%" } }}
                >
                  {components.map((component, index) => (
                    <Draggable key={component.id} draggableId={component.id.toString()} index={index}>
                      {(provided) => (
                        <List.Item {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                          <ComponentItem component={component} />
                        </List.Item>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </StrictModeDroppable>
          </DragDropContext>
        )}
      </Stack>
      <Button
        styles={{
          root: {
            "&:hover": {
              backgroundColor: "#EDEFF380",
            },
          },
          inner: {
            justifyContent: "flex-start",
          },
        }}
        variant="subtle"
        color="secondary-magenta"
        leftIcon={<NewSection />}
        onClick={openDrawer}
      >
        {t("tree-view.add")}
      </Button>
      <AddSectionDrawer componentDisplay={componentDisplay} opened={isDrawerOpen} close={closeDrawer} />
    </Stack>
  );
};

const TreeView = () => {
  const { t, i18n } = useTranslation("sections");
  const { activePage } = useBuilderQuery();

  const form = useFormContext<ITheme>();
  const pageIndex = form.getValues("pages").findIndex((page) => page.id === activePage);
  const page = form.getValues("pages")[pageIndex];

  return (
    <Box py={15}>
      <Text size={18} weight={500} color="primary-black" px={20}>
        {i18n.language === "ar" ? page?.nameAr : page?.nameEn}
      </Text>
      <Divider my={15} color="light-gray" />
      <TreeViewSection title={t("tree-view.sections.header")} controlName="topComponents" componentDisplay="top" />
      <Divider my={15} color="light-gray" />
      <TreeViewSection
        title={t("tree-view.sections.content")}
        controlName={`pages.${pageIndex}.pageComponents`}
        componentDisplay="content"
      />
      <Divider my={15} color="light-gray" />
      <TreeViewSection
        title={t("tree-view.sections.footer")}
        controlName="bottomComponents"
        componentDisplay="bottom"
      />
    </Box>
  );
};

export default TreeView;

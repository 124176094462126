import { ICompany, ZammitComponent } from "@zammittech/components";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useCollectionProducts } from "../../../hooks/queries/useCollectionProducts";

import { ICollectionComponent, ITheme } from "../../../typings/Theme";

type Props = { company: ICompany; component: ICollectionComponent; theme: ITheme };

const ComponentWithCollectionModel = ({ company, component, theme }: Props) => {
  const { isMobile, locale } = useBuilderQuery();
  const { data } = useCollectionProducts(String(component.modelIds));

  return (
    <ZammitComponent
      company={company}
      component={{ ...component, data }}
      theme={theme}
      isMobile={isMobile}
      locale={locale}
    />
  );
};

export default ComponentWithCollectionModel;

import { useQuery } from "@tanstack/react-query";

import { getStoreTemplates } from "../../api/StoreTemplates";
import { IMetadata } from "../../typings";
import { IStoreTemplate } from "../../typings/StoreTemplate";

type useStoreTemplatesParams = {
  search: string | null;
  price: string[];
  industry_tags: string[];
  catalog_size: string[];
  features_tags: string[];
  limit: number;
};

export const useStoreTemplates = (params?: useStoreTemplatesParams) => {
  return useQuery<{
    storeTemplates: IStoreTemplate[];
    metadata: IMetadata;
  }>({ queryKey: ["storeTemplates", params], queryFn: () => getStoreTemplates(params), keepPreviousData: true });
};

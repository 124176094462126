import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Control } from "react-hook-form";

import Tab from "./Tab";
import LangToggle from "./LangToggle";
import TypographyContent from "./TypographyContent";

import { ITheme } from "../../../typings/Theme";

const TypographyTab = ({ control }: { control: Control<ITheme> }) => {
  const { t } = useTranslation("settings");
  const [lang, setLang] = useState<"En" | "Ar">("En");

  return (
    <Tab tab="typography" title={t("typography.title")} desc={t("typography.description")}>
      <LangToggle lang={lang} setLang={setLang} />

      {lang === "En" && <TypographyContent lang="english" control={control} />}
      {lang === "Ar" && <TypographyContent lang="arabic" control={control} />}
    </Tab>
  );
};

export default TypographyTab;

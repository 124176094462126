import { Carousel } from "@mantine/carousel";
import { createStyles, getStylesRef } from "@mantine/core";

import ThemeSlide from "./ThemeSlide";

import { ITheme } from "../../../typings/Theme";

const useStyles = createStyles(() => ({
  controls: {
    ref: getStylesRef("controls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },

  root: {
    "&:hover": {
      [`& .${getStylesRef("controls")}`]: {
        opacity: 1,
      },
    },
  },
}));

const ThemesCarousel = ({ themes }: { themes: ITheme[] }) => {
  const { classes } = useStyles();

  return (
    <Carousel height={220} align="start" slideGap="xl" withControls withKeyboardEvents classNames={classes}>
      {themes.map((theme) => (
        <ThemeSlide key={theme.id} theme={theme} />
      ))}
    </Carousel>
  );
};

export default ThemesCarousel;

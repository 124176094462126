import axios from "axios";
import i18n from "i18next";

export const baseURL = import.meta.env.VITE_PUBLIC_API_HOST;
export const dashboardURL = import.meta.env.VITE_PUBLIC_DASHBOARD_HOST;

const API = axios.create({
  baseURL,
  withCredentials: true,
  headers: { "Content-Type": "application/json" },
});

API.interceptors.request.use((config) => {
  const locale = config.headers.locale || i18n.language || "en";
  config.headers.locale = locale;

  return config;
});

API.interceptors.response.use(null, (error) => {
  // redirect back to dashboard if user is not authenticated
  if (error.response.status === 401) window.location.href = `${dashboardURL}/${i18n.language}/`;

  return Promise.reject(error);
});

export default API;

import { useTranslation } from "react-i18next";
import { Control, UseFieldArrayUpdate, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Box, Divider, Group, MantineProvider, Overlay, Stack, Text, createStyles } from "@mantine/core";
import { Eye, EyeOff, Trash } from "tabler-icons-react";
import { Loader } from "@mantine/core";
import { ZammitComponent } from "@zammittech/components";
import { Helmet } from "react-helmet";

import ComponentWithCollectionModel from "./ComponentWithCollectionModel";
import ComponentWithNavigationModel from "./ComponentWithNavigationModel";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useCompany } from "../../../hooks/queries/useCompany";
import { useDeleteThemeComponent } from "../../../hooks/mutations/useDeleteThemeComponent";
import { filterComponent } from "../../../utils/builder";
import {
  ComponentPrefix,
  ICollectionComponent,
  INavigationComponent,
  ITheme,
  IThemeComponent,
} from "../../../typings/Theme";

type ComponentProps = {
  componentIndex: number;
  componentPrefix: ComponentPrefix;
  theme: ITheme;
  control: Control<ITheme>;
};

const useStyles = createStyles((theme) => ({
  wrapper: {
    scrollMarginTop: 50,
    cursor: "pointer",
    transition: "margin 0.5s ease",
    position: "relative",

    "&:hover": {
      border: `solid 2px ${theme.colors["secondary-magenta"][0]}`,
    },
  },
  selected: {
    border: `solid 2px ${theme.colors["secondary-magenta"][0]}`,
    marginTop: 50,
    marginBottom: 50,
  },
  titleMenuWrapper: {
    borderRadius: "20px 20px 0 0",
    backgroundColor: theme.colors["light-magenta"][0],
    top: 0,
    left: 5,
    transform: "translate(0, -106%)",
    position: "absolute",
    padding: "10px 90px 10px 10px",
    zIndex: 2,
  },
  topMenu: {
    right: 0,
    position: "absolute",
    padding: "10px 10px 10px 10px",
    cursor: "pointer",
  },
  topMenuIcons: {
    transition: "all 0.2s ease",
    stroke: theme.colors["secondary-magenta"][0],
    strokeWidth: 1,
    "&:hover": {
      strokeWidth: 1.5,
    },
  },
}));

const ComponentWrapper = ({ componentIndex, componentPrefix, theme, control }: ComponentProps) => {
  const { isMobile, locale } = useBuilderQuery();
  const component = useWatch({ control, name: `${componentPrefix}.${componentIndex}` });
  const { data: company } = useCompany();

  if (!company) return <></>;

  const themeComponent = filterComponent(component, locale);

  switch (component.model) {
    case "Collection":
      return (
        <ComponentWithCollectionModel
          company={company}
          component={themeComponent as ICollectionComponent}
          theme={theme}
        />
      );
    case "Navigation":
      return (
        <ComponentWithNavigationModel
          company={company}
          component={themeComponent as INavigationComponent}
          theme={theme}
        />
      );
    default:
      return <ZammitComponent component={themeComponent} theme={theme} isMobile={isMobile} locale={locale} />;
  }
};

const ComponentPreview = ({
  pageIndex,
  componentIndex,
  componentPrefix,
  component,
  update,
}: {
  pageIndex: number;
  componentIndex: number;
  componentPrefix: ComponentPrefix;
  component: IThemeComponent;
  update:
    | UseFieldArrayUpdate<ITheme, "topComponents">
    | UseFieldArrayUpdate<ITheme, "bottomComponents">
    | UseFieldArrayUpdate<ITheme, `pages.${number}.pageComponents`>;
}) => {
  const { classes, cx } = useStyles();
  const { i18n } = useTranslation();
  const { locale, activeComponent, setActiveComponent } = useBuilderQuery();

  const form = useFormContext<ITheme>();
  const theme = form.getValues();

  const isSelected = activeComponent === component.id;

  const { mutate: deleteThemeComponent, isLoading: isDeleteLoading } = useDeleteThemeComponent();

  const themeFontFamily = locale === "ar" ? theme.arabicFont : theme.englishFont;
  const fontSizes = locale === "ar" ? theme.arabicSizes : theme.englishSizes;

  return (
    <Box
      className={cx(classes.wrapper, { [classes.selected]: isSelected })}
      onClick={() => setActiveComponent(component.id)}
      // This forces to the whole array to remount, so a new useWatch is registered. This avoids issues when deleting components.
      key={`${componentPrefix}.${componentIndex}`}
      id={String(component.id)}
    >
      {isSelected && (
        <Group className={classes.titleMenuWrapper} dir={i18n.dir(i18n.language)}>
          <Text size={14} fw={400} color="secondary-magenta">
            {component.name}
          </Text>
          <Divider h={25} color="secondary-magenta" orientation="vertical" />
          <Group className={classes.topMenu}>
            {isDeleteLoading ? (
              <Loader color="secondary-magenta" variant="dots" size="sm" />
            ) : (
              <>
                {component.isHidden ? (
                  <Eye
                    className={classes.topMenuIcons}
                    onClick={() => update(componentIndex, { ...component, isHidden: false })}
                  />
                ) : (
                  <EyeOff
                    className={classes.topMenuIcons}
                    onClick={() => update(componentIndex, { ...component, isHidden: true })}
                  />
                )}

                <Trash
                  className={classes.topMenuIcons}
                  onClick={() => deleteThemeComponent({ id: component.id, pageIndex, display: component.display })}
                />
              </>
            )}
          </Group>
        </Group>
      )}
      <Box
        sx={{
          "*": { fontFamily: themeFontFamily },
          h1: { fontSize: fontSizes?.h1, margin: 0 },
          h2: { fontSize: fontSizes?.h2, margin: 0 },
          h3: { fontSize: fontSizes?.h3, margin: 0 },
          h4: { fontSize: fontSizes?.h4, margin: 0 },
          p: { fontSize: fontSizes?.body, margin: 0 },
        }}
      >
        {component.isHidden && <Overlay color="#F2F4F5" opacity={0.7} />}
        <Helmet>
          <link
            rel="stylesheet"
            href={`https://fonts.googleapis.com/css?family=${themeFontFamily?.replace(/\s/g, "+")}`}
          />
        </Helmet>
        <MantineProvider
          theme={{
            dir: i18n.dir(locale),
            defaultRadius: theme.radius,
            fontFamily: `${themeFontFamily} !important`,
            headings: {
              fontFamily: `${themeFontFamily} !important`,
              sizes: {
                h1: { fontSize: `${fontSizes?.h1}px` },
                h2: { fontSize: `${fontSizes?.h2}px` },
                h3: { fontSize: `${fontSizes?.h3}px` },
                h4: { fontSize: `${fontSizes?.h4}px` },
              },
            },
            components: {
              Button: {
                styles: {
                  root: {
                    fontSize: `${fontSizes?.btn}px`,
                  },
                },
              },
              Text: {
                styles: () => ({
                  root: {
                    fontSize: `${fontSizes?.body}px`,
                  },
                }),
              },
            },
          }}
          inherit
        >
          <ComponentWrapper
            componentIndex={componentIndex}
            componentPrefix={componentPrefix}
            theme={theme}
            control={form.control}
          />
        </MantineProvider>
      </Box>
    </Box>
  );
};

const PageComponents = ({ pageIndex, control }: { pageIndex: number; control: Control<ITheme> }) => {
  const { fields: pageComponents, update } = useFieldArray({
    control,
    name: `pages.${pageIndex}.pageComponents`,
    keyName: "formId",
  });

  return (
    <>
      {pageComponents?.map((component, index) => (
        <ComponentPreview
          key={component.id}
          pageIndex={pageIndex}
          componentIndex={index}
          componentPrefix={`pages.${pageIndex}.pageComponents`}
          component={component}
          update={update}
        />
      ))}
    </>
  );
};

const PagePreview = () => {
  const { i18n } = useTranslation();
  const { locale, activePage } = useBuilderQuery();

  const form = useFormContext<ITheme>();

  const { fields: topComponents, update: updateTopComponents } = useFieldArray({
    control: form.control,
    name: "topComponents",
    keyName: "formId",
  });
  const { fields: bottomComponents, update: updateBottomComponents } = useFieldArray({
    control: form.control,
    name: "bottomComponents",
    keyName: "formId",
  });
  const { fields: pages } = useFieldArray({
    control: form.control,
    name: "pages",
    keyName: "formId",
  });

  const pageIndex = pages.findIndex((page) => page.id === activePage);

  return (
    <Stack spacing={0} dir={i18n.dir(locale)}>
      {topComponents.map((component, index) => (
        <ComponentPreview
          key={component.id}
          pageIndex={pageIndex}
          componentIndex={index}
          componentPrefix="topComponents"
          component={component}
          update={updateTopComponents}
        />
      ))}
      <PageComponents pageIndex={pageIndex} control={form.control} />
      {bottomComponents.map((component, index) => (
        <ComponentPreview
          key={component.id}
          pageIndex={pageIndex}
          componentIndex={index}
          componentPrefix="bottomComponents"
          component={component}
          update={updateBottomComponents}
        />
      ))}
    </Stack>
  );
};

export default PagePreview;

const FooterIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" fill="#EDEFF3" />
      <rect x="2" y="16" width="26" height="12" fill="#121118" />
      <path d="M2 12.9134V7H5.90846V8.0308H3.24805V9.43985H5.64905V10.4706H3.24805V12.9134H2Z" fill="#4F3FA8" />
      <path
        d="M8.40024 13C7.95252 13 7.56532 12.9047 7.23866 12.7141C6.91391 12.5217 6.66315 12.2541 6.48637 11.9115C6.30958 11.5669 6.22119 11.1675 6.22119 10.7132C6.22119 10.2551 6.30958 9.85467 6.48637 9.51203C6.66315 9.16747 6.91391 8.8999 7.23866 8.70934C7.56532 8.51684 7.95252 8.4206 8.40024 8.4206C8.84797 8.4206 9.2342 8.51684 9.55894 8.70934C9.88561 8.8999 10.1373 9.16747 10.3141 9.51203C10.4909 9.85467 10.5793 10.2551 10.5793 10.7132C10.5793 11.1675 10.4909 11.5669 10.3141 11.9115C10.1373 12.2541 9.88561 12.5217 9.55894 12.7141C9.2342 12.9047 8.84797 13 8.40024 13ZM8.40601 12.0472C8.60969 12.0472 8.77975 11.9894 8.91618 11.8739C9.05261 11.7565 9.15542 11.5967 9.22459 11.3946C9.29569 11.1925 9.33124 10.9625 9.33124 10.7045C9.33124 10.4466 9.29569 10.2166 9.22459 10.0144C9.15542 9.81232 9.05261 9.65255 8.91618 9.53513C8.77975 9.41771 8.60969 9.359 8.40601 9.359C8.2004 9.359 8.02746 9.41771 7.88719 9.53513C7.74883 9.65255 7.64411 9.81232 7.57301 10.0144C7.50383 10.2166 7.46925 10.4466 7.46925 10.7045C7.46925 10.9625 7.50383 11.1925 7.57301 11.3946C7.64411 11.5967 7.74883 11.7565 7.88719 11.8739C8.02746 11.9894 8.2004 12.0472 8.40601 12.0472Z"
        fill="#4F3FA8"
      />
      <path
        d="M13.378 13C12.9303 13 12.5431 12.9047 12.2165 12.7141C11.8917 12.5217 11.641 12.2541 11.4642 11.9115C11.2874 11.5669 11.199 11.1675 11.199 10.7132C11.199 10.2551 11.2874 9.85467 11.4642 9.51203C11.641 9.16747 11.8917 8.8999 12.2165 8.70934C12.5431 8.51684 12.9303 8.4206 13.378 8.4206C13.8258 8.4206 14.212 8.51684 14.5367 8.70934C14.8634 8.8999 15.1151 9.16747 15.2919 9.51203C15.4687 9.85467 15.5571 10.2551 15.5571 10.7132C15.5571 11.1675 15.4687 11.5669 15.2919 11.9115C15.1151 12.2541 14.8634 12.5217 14.5367 12.7141C14.212 12.9047 13.8258 13 13.378 13ZM13.3838 12.0472C13.5875 12.0472 13.7576 11.9894 13.894 11.8739C14.0304 11.7565 14.1332 11.5967 14.2024 11.3946C14.2735 11.1925 14.309 10.9625 14.309 10.7045C14.309 10.4466 14.2735 10.2166 14.2024 10.0144C14.1332 9.81232 14.0304 9.65255 13.894 9.53513C13.7576 9.41771 13.5875 9.359 13.3838 9.359C13.1782 9.359 13.0053 9.41771 12.865 9.53513C12.7266 9.65255 12.6219 9.81232 12.5508 10.0144C12.4816 10.2166 12.4471 10.4466 12.4471 10.7045C12.4471 10.9625 12.4816 11.1925 12.5508 11.3946C12.6219 11.5967 12.7266 11.7565 12.865 11.8739C13.0053 11.9894 13.1782 12.0472 13.3838 12.0472Z"
        fill="#4F3FA8"
      />
      <path
        d="M18.7104 8.47834V9.40231H16.0442V8.47834H18.7104ZM16.6495 7.41578H17.8774V11.5505C17.8774 11.6641 17.8947 11.7526 17.9293 11.8162C17.9639 11.8778 18.0119 11.9211 18.0734 11.9461C18.1368 11.9711 18.2098 11.9836 18.2924 11.9836C18.3501 11.9836 18.4077 11.9788 18.4654 11.9692C18.523 11.9577 18.5672 11.949 18.598 11.9432L18.7911 12.8585C18.7296 12.8778 18.6431 12.8999 18.5317 12.9249C18.4202 12.9519 18.2848 12.9682 18.1253 12.974C17.8293 12.9856 17.5699 12.9461 17.347 12.8556C17.1261 12.7652 16.9541 12.6246 16.8311 12.4341C16.7081 12.2435 16.6476 12.0029 16.6495 11.7122V7.41578Z"
        fill="#4F3FA8"
      />
      <path
        d="M21.4803 13C21.0249 13 20.6329 12.9076 20.3043 12.7228C19.9777 12.5361 19.7259 12.2724 19.5491 11.9317C19.3724 11.589 19.284 11.1838 19.284 10.7161C19.284 10.2599 19.3724 9.85948 19.5491 9.51492C19.7259 9.17036 19.9748 8.90183 20.2957 8.70934C20.6185 8.51684 20.997 8.4206 21.4313 8.4206C21.7234 8.4206 21.9953 8.46776 22.247 8.56208C22.5007 8.65448 22.7216 8.79403 22.91 8.98075C23.1002 9.16747 23.2482 9.40231 23.3538 9.68527C23.4595 9.96631 23.5124 10.2955 23.5124 10.6728V11.0106H19.774V10.2483H22.3565C22.3565 10.0712 22.3181 9.91434 22.2413 9.77767C22.1644 9.641 22.0577 9.53417 21.9213 9.45717C21.7868 9.37825 21.6302 9.33879 21.4515 9.33879C21.2651 9.33879 21.0998 9.3821 20.9557 9.46872C20.8135 9.55342 20.7021 9.66795 20.6214 9.81232C20.5407 9.95476 20.4994 10.1136 20.4974 10.2887V11.0135C20.4974 11.2329 20.5378 11.4225 20.6185 11.5823C20.7011 11.7421 20.8174 11.8653 20.9673 11.9519C21.1171 12.0385 21.2949 12.0818 21.5005 12.0818C21.6369 12.0818 21.7618 12.0626 21.8752 12.0241C21.9886 11.9856 22.0856 11.9278 22.1663 11.8508C22.247 11.7738 22.3085 11.6795 22.3508 11.5679L23.4864 11.6429C23.4288 11.9163 23.3106 12.155 23.1319 12.359C22.9551 12.5611 22.7264 12.719 22.4459 12.8325C22.1673 12.9442 21.8454 13 21.4803 13Z"
        fill="#4F3FA8"
      />
      <path
        d="M24.3137 12.9134V8.47834H25.5041V9.25217H25.5502C25.6309 8.9769 25.7664 8.76901 25.9566 8.62849C26.1468 8.48604 26.3659 8.41482 26.6138 8.41482C26.6753 8.41482 26.7416 8.41867 26.8126 8.42637C26.8837 8.43407 26.9462 8.44466 27 8.45813V9.54957C26.9424 9.53224 26.8626 9.51684 26.7608 9.50337C26.6589 9.48989 26.5657 9.48316 26.4812 9.48316C26.3006 9.48316 26.1391 9.52262 25.9969 9.60154C25.8567 9.67854 25.7452 9.78633 25.6626 9.92493C25.5819 10.0635 25.5415 10.2233 25.5415 10.4042V12.9134H24.3137Z"
        fill="#4F3FA8"
      />
    </svg>
  );
};

export default FooterIcon;

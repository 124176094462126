import React from "react";
import { FieldPath, useFormContext, useWatch } from "react-hook-form";
import { Switch, TextInput, Slider, NumberInput } from "react-hook-form-mantine";
import { Box, MantineProvider } from "@mantine/core";

import { default as BuilderDropdown } from "../../common/Dropdown";
import { default as BuilderMultiSelect } from "../../common/Multiselect";
import { TextEditor } from "../components/RichTextEditor";
import ImageEditor from "./propEditors/ImageEditor";
import CurrencyEditor from "./propEditors/CurrencyEditor";
import BoxModelEditor from "./propEditors/BoxModelEditor";
import EditorLabel from "./propEditors/EditorLabel";
import ColorInput from "../components/ColorInput";
import InputLocaleSwitcher from "../components/InputLocaleSwitcher";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useCompany } from "../../../hooks/queries/useCompany";
import { IPropValue, ITheme, IThemeForm, PropKey } from "../../../typings/Theme";
import { getThemeColors } from "../../../utils/settings";
import { hasFeature } from "../../../utils";
import { useTranslation } from "react-i18next";

type Props = { prop: IPropValue; form: IThemeForm; name: FieldPath<ITheme>; localeSwitcher?: React.ReactNode };

const Text = ({ prop, form, name, localeSwitcher }: Props) => {
  return (
    <TextInput
      label={<EditorLabel label={prop.label} hint={prop.hint} localeSwitcher={localeSwitcher} />}
      control={form.control}
      name={name}
    />
  );
};

const Link = ({ prop, form, name }: Props) => {
  const value = useWatch({ control: form.control, name }) as string | undefined;
  const { data: company } = useCompany();
  const isFullLink = value?.includes("myzammit") || (company?.customUrl && value?.includes(company.customUrl));

  const { t } = useTranslation("sections");
  return (
    <TextInput
      label={<EditorLabel label={prop.label} hint={prop.hint} />}
      control={form.control}
      name={name}
      error={isFullLink && t("config-sidebar.full-path-error")}
    />
  );
};

const Number = ({ prop, form, name }: Props) => {
  return (
    <NumberInput
      label={<EditorLabel label={prop.label} hint={prop.hint} />}
      control={form.control}
      name={name}
      {...(prop.min && { min: prop.min })}
      {...(prop.max && { max: prop.max })}
    />
  );
};

const FreeText = ({ prop, form, name, localeSwitcher }: Props) => {
  const theme = form.getValues();
  const { locale } = useBuilderQuery();

  const colors = getThemeColors(theme);

  const themeFontFamily = locale === "ar" ? theme.arabicFont : theme.englishFont;
  const fontSizes = locale === "ar" ? theme.arabicSizes : theme.englishSizes;

  return (
    <Box mb="sm">
      <EditorLabel label={prop.label} hint={prop.hint} localeSwitcher={localeSwitcher} />
      <Box
        sx={{
          "*": { fontFamily: themeFontFamily },
          h1: { fontSize: fontSizes?.h1, margin: 0 },
          h2: { fontSize: fontSizes?.h2, margin: 0 },
          h3: { fontSize: fontSizes?.h3, margin: 0 },
          h4: { fontSize: fontSizes?.h4, margin: 0 },
          p: { fontSize: fontSizes?.body, margin: 0 },
        }}
      >
        <MantineProvider
          theme={{
            fontFamily: `${themeFontFamily} !important`,
            headings: {
              fontFamily: `${themeFontFamily} !important`,
              sizes: {
                h1: { fontSize: `${fontSizes?.h1}px` },
                h2: { fontSize: `${fontSizes?.h2}px` },
                h3: { fontSize: `${fontSizes?.h3}px` },
                h4: { fontSize: `${fontSizes?.h4}px` },
              },
            },
          }}
          inherit
        >
          <TextEditor
            value={prop.value as string}
            colors={colors}
            setValue={(value) => {
              form.setValue(name, value);
            }}
          />
        </MantineProvider>
      </Box>
    </Box>
  );
};

const Toggle = ({ prop, form, name }: Props) => {
  return (
    <Switch
      color="primary-green"
      labelPosition="left"
      label={<EditorLabel label={prop.label} hint={prop.hint} mb={0} />}
      control={form.control}
      name={name}
    />
  );
};

const Dropdown = ({ prop, form, name }: Props) => {
  return (
    <BuilderDropdown
      allowDeselect
      label={<EditorLabel label={prop.label} hint={prop.hint} />}
      data={prop.options || []}
      control={form.control}
      name={name}
    />
  );
};

const MultiSelect = ({ prop, form, name }: Props) => {
  return (
    <BuilderMultiSelect
      label={<EditorLabel label={prop.label} hint={prop.hint} />}
      data={prop.options || []}
      control={form.control}
      name={name}
    />
  );
};

const Range = ({ prop, form, name }: Props) => {
  const marks = [
    { value: prop.min || 0, label: prop.min },
    { value: prop.max || 0, label: prop.max },
  ];

  return (
    <Box mb="sm">
      <EditorLabel label={prop.label} hint={prop.hint} />
      <Slider
        color="secondary-magenta"
        marks={marks}
        min={prop.min as number | undefined}
        max={prop.max as number | undefined}
        control={form.control}
        name={name}
      />
    </Box>
  );
};

const Color = ({ prop, form, name }: Props) => {
  return (
    <ColorInput
      name={name}
      label={<EditorLabel label={prop.label} hint={prop.hint} mb={0} />}
      control={form.control}
      showColorWheel
    />
  );
};

const Spacing = ({ prop, form, name }: Props) => {
  return (
    <Box mb="sm">
      <EditorLabel label={prop.label} hint={prop.hint} />
      <BoxModelEditor name={name} control={form.control} />
    </Box>
  );
};

const Image = ({ prop, form, name, localeSwitcher }: Props) => {
  const imageUrl = useWatch({ control: form.control, name }) as string | undefined;

  return (
    <ImageEditor
      name={name}
      label={prop.label}
      hint={prop.hint}
      imageUrl={imageUrl}
      localeSwitcher={localeSwitcher}
      setValue={form.setValue}
    />
  );
};

const Currency = ({ prop, form, name }: Props) => {
  return (
    <CurrencyEditor
      name={name}
      label={<EditorLabel label={prop.label} hint={prop.hint} />}
      control={form.control}
      setValue={form.setValue}
    />
  );
};

const ComponentMap: Record<PropKey, React.FC<Props>> = {
  text: Text,
  number: Number,
  link: Link,
  slider: Range,
  rich_text: FreeText,
  dropdown: Dropdown,
  flag_toggle: Toggle,
  color: Color,
  image: Image,
  checkbox: MultiSelect,
  currency: Currency,
  spacing: Spacing,
} as const;

const PropEditor = (props: Omit<Props, "form" | "localeSwitcher">) => {
  const isTranslated = props.prop.isValueTranslated;
  const form = useFormContext<ITheme>();

  const { data: company } = useCompany();

  const component = ComponentMap[props.prop.propType] || React.Fragment;

  return React.createElement(component, {
    ...props,
    form,
    localeSwitcher: isTranslated && company && hasFeature(company, "bilingual") ? <InputLocaleSwitcher /> : null,
  });
};

export default PropEditor;

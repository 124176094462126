import { useQuery } from "@tanstack/react-query";

import { useBuilderQuery } from "../custom/useBuilderQuery";
import { getCollectionProducts } from "../../api/Collection";
import { ICollectionProduct } from "../../typings/Collection";

export const useCollectionProducts = (id?: string) => {
  const { locale } = useBuilderQuery();
  return useQuery<ICollectionProduct[]>({
    queryKey: ["products", locale, id],
    queryFn: () => {
      if (!id) return [];
      return getCollectionProducts({ id, locale });
    },
    keepPreviousData: true,
  });
};

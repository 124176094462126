import API from "./API";
import { apiPrefix } from "./constants";
import { IComponent } from "../typings/Theme";

type ComponentResponse = {
  success: boolean;
  components: IComponent[];
};

export const getComponents = async (): Promise<IComponent[]> => {
  const response = await API.get<ComponentResponse>(`${apiPrefix}/components`);
  return response.data.components;
};

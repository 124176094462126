import { Group, Avatar, Text, Box, Skeleton, Stack } from "@mantine/core";
import { useUser } from "../../../hooks/queries/useUser";

const getInitials = (name: string) => {
  const initials = name.split(" ").map((n) => n[0]);
  return initials.join("");
};

const getColor = (name: string) => {
  const colors = [
    "gray",
    "pink",
    "grape",
    "violet",
    "yellow",
    "cyan",
    "red",
    "blue",
    "green",
    "indigo",
    "dark",
    "teal",
    "lime",
    "orange",
  ];

  const hash = name.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);

  const index = hash % colors.length;
  return colors[index];
};

const Account = () => {
  const { data: user, isLoading } = useUser();

  if (isLoading) {
    return (
      <Group spacing={15}>
        <Skeleton height={40} circle />
        <Stack spacing={8}>
          <Skeleton w={100} height={8} radius="xl" />
          <Skeleton w={150} height={8} radius="xl" />
        </Stack>
      </Group>
    );
  }

  if (!user) return <>?</>;

  return (
    <Group spacing={15}>
      <Avatar w={40} h={40} alt={user.name} radius="xl" color={getColor(user.name)} variant="filled">
        {getInitials(user.name)}
      </Avatar>

      <Box style={{ flex: 1 }}>
        <Text size={16} weight={500} color="primary-black">
          {user.name}
        </Text>

        <Text size={14} weight={400} color="med-gray">
          {user.email}
        </Text>
      </Box>
    </Group>
  );
};

export default Account;

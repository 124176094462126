import { Center } from "@mantine/core";

type Props = { device: "mobile" | "desktop"; children: React.ReactNode };

function DeviceFrame({ device, children }: Props) {
  if (device === "desktop") return <>{children}</>;

  return (
    <Center>
      <div className="marvel-device iphone-x">
        <div className="notch">
          <div className="camera" />
          <div className="speaker" />
        </div>
        <div className="top-bar" />
        <div className="sleep" />
        <div className="bottom-bar" />
        <div className="volume" />
        <div className="overflow">
          <div className="shadow shadow--tr" />
          <div className="shadow shadow--tl" />
          <div className="shadow shadow--br" />
          <div className="shadow shadow--bl" />
        </div>
        <div className="inner-shadow" />
        <div className="screen">{children}</div>
      </div>
    </Center>
  );
}

export default DeviceFrame;

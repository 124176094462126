import { useMantineTheme, Button as MantineButton, ButtonProps as MantineButtonProps } from "@mantine/core";
import { ReactNode } from "react";

interface ButtonProps extends MantineButtonProps {
  name?: string;
  mode: "primary" | "secondary" | "alarm";
  children: ReactNode;
  onClick?: () => void;
}

const Button = ({ name, mode, children, onClick, ...props }: ButtonProps) => {
  const theme = useMantineTheme();

  const bgColor = {
    primary: theme.colors["primary-black"][0],
    secondary: theme.white,
    alarm: theme.white,
  };
  const txtColor = {
    primary: theme.white,
    secondary: theme.colors["primary-black"][0],
    alarm: theme.colors["red"][0],
  };

  return (
    <MantineButton
      name={name}
      styles={(theme) => ({
        root: {
          borderRadius: 5,
          backgroundColor: bgColor[mode],
          borderColor: theme.colors[mode === "alarm" ? "red" : "primary-black"][0],

          "&:hover": {
            backgroundColor: bgColor[mode],
            borderColor: theme.colors[mode === "alarm" ? "red" : "primary-black"][0],
          },
        },
        label: {
          color: txtColor[mode],
          fontSize: 14,
          fontWeight: 400,
          textTransform: "capitalize",

          "&:hover": {
            color: txtColor[mode],
          },
        },
      })}
      loaderProps={{
        color: txtColor[mode],
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </MantineButton>
  );
};

export default Button;

import { useTranslation } from "react-i18next";
import { Loader } from "@mantine/core";
import { useFormContext } from "react-hook-form";

import MultiSelect from "../../../common/Multiselect";
import EditorLabel from "./EditorLabel";

import { useCollections } from "../../../../hooks/queries/useCollections";
import { ITheme } from "../../../../typings/Theme";

type Props = {
  prefix: `pages.${number}.pageComponents.${number}`;
};

const CollectionsDropdown = ({ prefix }: Props) => {
  const { t } = useTranslation("sections");
  const form = useFormContext<ITheme>();
  const { isLoading, data: collections } = useCollections();

  return (
    <MultiSelect
      searchable
      label={<EditorLabel label={t("config-sidebar.select-collection")} />}
      rightSection={isLoading ? <Loader size={25} /> : undefined}
      data={collections?.map(({ id, name }) => ({ label: name, value: String(id) })) || []}
      maxSelectedValues={1}
      control={form.control}
      name={`${prefix}.modelIds`}
    />
  );
};

export default CollectionsDropdown;

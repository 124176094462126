import { useTranslation } from "react-i18next";
import { Control, UseFormSetValue } from "react-hook-form";
import { Switch, TextInput } from "react-hook-form-mantine";
import { Box, Divider, Group, Stack, Text } from "@mantine/core";

import Section from "../../common/Section";
import Button from "../../common/Button";
import InputLabel from "./InputLabel";

import { useBuilderQuery } from "../../../hooks/custom/useBuilderQuery";
import { useCompany } from "../../../hooks/queries/useCompany";
import { useDeletePage } from "../../../hooks/mutations/useDeletePage";
import { ITheme } from "../../../typings/Theme";
import { IPage } from "../../../typings/Page";
import { formatInputName, formatPageHandle, getShopUrl } from "../../../utils/pages";

type Props = {
  pageIndex?: number;
  page: IPage;
  control: Control<ITheme> | Control<IPage>;
  setValue?: UseFormSetValue<IPage>;
};

const PageSettings = ({ pageIndex, page, control }: Props) => {
  const { t } = useTranslation("pages");
  const { locale } = useBuilderQuery();

  const { data: company } = useCompany();
  const { mutate: deletePage, isLoading: isDeletePageLoading } = useDeletePage();

  return (
    <Section>
      <Text size={18} weight={500}>
        {t("settings.title")}
      </Text>
      <Divider my={20} color="light-gray" />
      <Stack spacing={25}>
        <Box w="100%">
          <InputLabel label={t("settings.name")} withLocale />

          {page.id ? (
            <TextInput
              size="md"
              name={formatInputName({ name: "name", pageIndex, locale }) as keyof ITheme}
              control={control as Control<ITheme>}
            />
          ) : (
            <TextInput
              size="md"
              name={formatInputName({ name: "name", locale }) as keyof IPage}
              control={control as Control<IPage>}
            />
          )}
        </Box>
        <Box w="100%">
          <InputLabel label={t("settings.desc")} withLocale />

          {page.id ? (
            <TextInput
              size="md"
              name={formatInputName({ name: "description", pageIndex, locale }) as keyof ITheme}
              control={control as Control<ITheme>}
            />
          ) : (
            <TextInput
              size="md"
              name={formatInputName({ name: "description", locale }) as keyof IPage}
              control={control as Control<IPage>}
            />
          )}
        </Box>
        <Box w="100%">
          <InputLabel label={t("settings.handle")} />

          {page.id ? (
            <TextInput
              disabled={page?.isStatic}
              iconWidth={getShopUrl(company).length * 9 + 20}
              icon={<Text mx={5}>{`${getShopUrl(company)}/`}</Text>}
              size="md"
              name={formatInputName({ name: "handle", pageIndex }) as keyof ITheme}
              control={control as Control<ITheme>}
            />
          ) : (
            <TextInput
              iconWidth={getShopUrl(company).length * 9 + 20}
              icon={<Text mx={5}>{`${getShopUrl(company)}/`}</Text>}
              size="md"
              name={formatInputName({ name: "handle" }) as keyof IPage}
              control={control as Control<IPage>}
              placeholder={formatPageHandle(page)}
            />
          )}
        </Box>
        {page.id ? (
          <Group position="apart">
            <Button
              mode="alarm"
              disabled={page.isStatic}
              loading={isDeletePageLoading}
              onClick={() => deletePage(page.id)}
            >
              {t("settings.delete-btn")}
            </Button>
            <Group>
              <Text size={14} weight={400} color="high-gray">
                {t("settings.hide-toggle")}
              </Text>
              <Switch
                disabled={page?.isStatic}
                name={formatInputName({ name: "isHidden", pageIndex }) as keyof ITheme}
                control={control as Control<ITheme>}
                color="secondary-magenta"
              />
            </Group>
          </Group>
        ) : null}
      </Stack>
    </Section>
  );
};

export default PageSettings;

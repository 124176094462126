import { Image, Text } from "@mantine/core";

import Section from "../../common/Section";

import colorsBanner from "../../../assets/colors-banner.png";
import typographyBanner from "../../../assets/typography-banner.png";
import logosBanner from "../../../assets/logos-banner.png";
import componentsBanner from "../../../assets/components-banner.png";

import { SettingsTab } from "../../../typings";

const BannerImages = {
  colors: colorsBanner,
  typography: typographyBanner,
  logos: logosBanner,
  components: componentsBanner,
} as const;

const Banner = ({ title, desc, tab }: { title: string; desc: string; tab: SettingsTab }) => {
  return (
    <Section>
      <Image src={BannerImages[tab]} alt={title} height={200} mb={20} />
      <Text size={30} lh={1.2} weight={400} mb={10} color="primary-black">
        {title}
      </Text>
      <Text size={16} weight={300} color="primary-black">
        {desc}
      </Text>
    </Section>
  );
};

export default Banner;

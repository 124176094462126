import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavigateOptions, URLSearchParamsInit } from "react-router-dom";
import { Accordion, Box, Paper, Text, Anchor, Checkbox, CheckboxProps, Image } from "@mantine/core";
import { useScrollIntoView } from "@mantine/hooks";

import { ITemplateFilterCategory } from "../../../typings/StoreTemplate";

import downArrow from "../../../assets/icons/black-down-arrow.svg";
import check from "../../../assets/icons/check.svg";

type Props = {
  filterCategories: ITemplateFilterCategory[] | undefined;
  filters: URLSearchParams;
  setFilters: (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit) | undefined,
    navigateOpts?: NavigateOptions | undefined,
  ) => void;
};

// eslint-disable-next-line react/prop-types
const CheckboxIcon: CheckboxProps["icon"] = ({ indeterminate, className }) =>
  !indeterminate ? <img src={check} className={className} /> : null;

const ThemeFilter = ({ filterCategories, filters, setFilters }: Props) => {
  const { t } = useTranslation("home");

  const handleResetFilter = () => {
    setFilters("");
  };

  const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
    offset: 200,
  });

  const handleFilter = (categoryName: string, options: string[]) => {
    const checkOption = options[options.length - 1];

    if (checkOption && !filters.getAll(categoryName).includes(checkOption)) {
      filters.append(categoryName, checkOption);
    } else {
      filters.delete(categoryName);
      for (let i = 0; i < options.length; i++) {
        filters.append(categoryName, options[i]);
      }
    }

    filters.set("page", "1");
    setFilters(filters);
    scrollIntoView();
  };

  return (
    <Paper shadow="xs" p={20} bg="light-gray" sx={{ minWidth: 323 }} ref={targetRef}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Text weight={800} align="left" size={16}>
          {t("themes_store.filters.title")}
        </Text>
        <Anchor underline weight={400} size={14} color="secondary-magenta" onClick={handleResetFilter}>
          {t("themes_store.filters.reset")}
        </Anchor>
      </Box>
      {filterCategories && (
        <Accordion
          multiple
          chevron={<Image src={downArrow} alt="down arrow" width={18} />}
          defaultValue={filterCategories.map((category) => category.categoryName)}
          styles={(theme) => ({
            control: {
              paddingLeft: 0,
              fontSize: 14,

              ":hover": {
                backgroundColor: "transparent",
              },
            },
            content: {
              fontSize: 14,
              padding: 0,
            },
            label: {
              paddingBottom: 10,
              paddingTop: 10,
            },
            item: {
              borderTop: `1px solid ${theme.colors["light-red"][2]}`,
              borderBottom: "none",
              marginTop: 10,
            },
          })}
        >
          {filterCategories.map((category) => {
            const checkBoxValue =
              category.categoryName === "price"
                ? filters.getAll("price")
                : category.categoryName === "industry"
                ? filters.getAll("industry")
                : category.categoryName === "catalog_size"
                ? filters.getAll("catalog_size")
                : category.categoryName === "features"
                ? filters.getAll("features")
                : [];

            return (
              <Accordion.Item value={category.categoryName} key={category.categoryName}>
                <Accordion.Control>
                  {(category.categoryName.charAt(0).toUpperCase() + category.categoryName.slice(1)).replace("_", " ")}
                </Accordion.Control>
                <Accordion.Panel>
                  <FilterCategory
                    categoryName={category.categoryName}
                    options={category.options}
                    checkBoxValue={checkBoxValue}
                    handleFilter={handleFilter}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            );
          })}
        </Accordion>
      )}
    </Paper>
  );
};

type FilterCategoryProps = {
  categoryName: string;
  options: { name: string; count: number }[];
  checkBoxValue: string[];
  handleFilter: (categoryName: string, options: string[]) => void;
};

const FilterCategory = ({ categoryName, options, checkBoxValue, handleFilter }: FilterCategoryProps) => {
  const [viewMore, setViewMore] = useState(false);

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  return (
    <>
      <Checkbox.Group
        color="secondary-magenta"
        value={checkBoxValue}
        onChange={(value) => handleFilter(categoryName, value)}
      >
        {options.map((option, index) => {
          if (index < 10 || viewMore) {
            return (
              <Checkbox
                styles={(theme) => ({
                  root: {
                    paddingTop: 10,
                  },
                  input: {
                    borderColor: theme.colors["secondary-magenta"],
                    width: 25,
                    height: 25,
                  },
                  body: {
                    height: 25,
                  },
                  labelWrapper: {
                    alignSelf: "center",
                  },
                  icon: {
                    width: 9,
                    height: 8,
                    alignSelf: "center",
                  },
                  inner: {
                    display: "table",
                  },
                  label: {
                    paddingLeft: 10,
                  },
                })}
                color="secondary-magenta"
                icon={CheckboxIcon}
                key={option.name}
                label={option.name.charAt(0).toUpperCase() + option.name.slice(1) + " (" + option.count + ")"}
                value={option.name}
              />
            );
          }
        })}
      </Checkbox.Group>
      {options?.length > 10 && (
        <Anchor
          sx={(theme) => ({
            paddingTop: 15,
            display: "block",
            width: "fit-content",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "16.9px",
            color: theme.colors["secondary-magenta"][0],
          })}
          onClick={handleViewMore}
        >
          {viewMore ? "View less" : "View more"}
        </Anchor>
      )}
    </>
  );
};

export default ThemeFilter;
